.sec-role {
	padding: 31px 0 185px;
	@include mq {
		padding: 0 0 50px;
	}
}
.role-flex {
	@include flex(between, start, wrap);
	position: relative;
	margin-bottom: 80px;
	@include mq {
		margin-bottom: 50px;
	}
	&__item {
		width: 47.2%;
		max-width: 580px;
		@include mq(sm) {
			width: 100%;
			max-width: 100%;
			margin-bottom: 50px;
			position: relative;
		}
		&:after {
			position: absolute;
			content: "";
			border-left: 21px solid #000;
			border-top: 12px solid transparent;
			border-bottom: 12px solid transparent;
			top: 51.5%;
			left: 50%;
			@include mq(sm) {
				border-top: 14px solid #000;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				top: calc(100% + 24px);
				left: calc(50% - 8px);
			}
		}
		&:last-child {
			&:after {
				display: none;
			}
			@include mq(sm) {
				margin-bottom: 0;
			}
		}
	}
	&__ttl {
		margin-bottom: 7px;
		color: #0079FF;
	}
}
.role-position {
	max-width: 1046px;
	margin: 0 auto;
	&__item {
		border: 2px solid black;
		border-radius: 20px;
		padding: 56px 76px 67px;
		margin-bottom: 80px;
		@include mq(ipd) {
			padding: 30px;
		}
		@include mq {
			margin-bottom: 30px;
			padding: 30px 15px;
			border-radius: 10px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
