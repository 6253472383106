#header {
	position: fixed;
	width: 100%;
	max-width: 1202px;
	background: white;
	border-bottom-right-radius: 20px;
	padding: 50px 0 36px 80px;
	top: 0;
	left: 0;
	z-index: 9999;
	transition: 0.3s ease;
	@include mq(xl) {
		padding: 50px 0 36px;
	}
	@include mq(ipd) {
		border-radius: 0;
		padding: 9px 0;
	}
	@include mq {
		background: transparent;
	}
	&.js-scrolled {
		background: white;
	}
	.inner {
		@include mq(md-min) {
			@include flex(between, center);
		}
	}
	.header-logo {
		display: block;
		width: 216px;
		@include mq {
			width: 107px;
		}
		&:hover {
			opacity: 0.4;
		}
	}

	.header-nav {
		width: calc(100% - 245px);
		padding-bottom: 10px;
		@include mq(ipd) {
			padding-bottom: 0;
		}
		@include mq {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			background: rgba(0, 0, 0, 0.37);
			z-index: -1;
			opacity: 0;
			pointer-events: none;
			overflow-y: scroll;
			transition: 0.3s ease;
			&.active {
				opacity: 1;
				z-index: 1;
				pointer-events: auto;
				transition: 0.3s ease;
				.header-nav__menu {
					right: 0;
					transition: 0.3s ease;
				}
			}
		}

		&__menu {
			@include flex(end, center);
			@include mq {
				position: absolute;
				display: block;
				width: 67.7%;
				min-height: 100vh;
				background: white;
				padding-top: 68px;
				top: 0;
				right: -100%;
				transition: 0.3s ease;
			}

			&-item {
				margin-right: 33px;
				@include mq {
					border-top: 1px solid black;
					margin: 0 0 0 66px;
				}
				@include mq(xs) {
					margin-left: 25px;
				}
				&:nth-last-of-type(2) {
					margin-right: 48px;
					@include mq(xl) {
						margin-right: 0;
					}
				}

				> a {
					display: block;
					font-weight: bold;
					color: black;
					@include font-size(20);
					@include mq(xl) {
						@include font-size(16);
					}
					@include mq {
						padding: 16px 15px 15px 0;
					}
					&:hover {
						font-weight: 600;
						color: #4C94F7;
					}
				}
			}
			&-btn {
				margin-top: 17px;
				> a {
					@include flex(center, center);
					width: 100%;
					max-width: 171px;
					height: 48px;
					background: linear-gradient(90deg, #DBAAB6, #3979F6);
					color: white;
					border-radius: 50px;
					font-weight: bold;
					@include font-size(12);
					margin: 0 auto;
					span {
						position: relative;
						padding-right: 10px;
						&:after {
							content: "";
							position: absolute;
							width: 0;
							height: 0;
							border-top: 6px solid transparent;
							border-left: 9px solid #fff;
							border-bottom: 6px solid transparent;
							top: 3px;
							right: -7px;
						}
					}
				}
			}
		}
	}

	.js-mobile {
		width: 28px;
		height: 30px;
		position: absolute;
		right: 20px;
		@include v-align;
		cursor: pointer;
		z-index: 12;
		display: none;
		@include mq {
			display: block;
		}

		span {
			position: absolute;
			@include v-align;
			display: block;
			width: 100%;
			height: 2px;
			background: #7B8288;

			&:before, &:after {
				content: '';
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				background: #7B8288;
				transition: transform 0.3s;
			}

			&:before {
				top: -9px;
			}

			&:after {
				bottom: -9px;
			}
		}

		&--close {
			right: 16px;
			span {
				background-color: transparent;

				&:before, &:after {
					width: 100%;
				}

				&:before {
					transform: rotate(45deg);
					top: 2px;
				}

				&:after {
					transform: rotate(-45deg);
					bottom: -2px;
				}
			}
		}
	}
}


.js-locked {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;

	body {
		-webkit-overflow-scrolling: auto;
	}
}