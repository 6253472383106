@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Font
==================================================== */
/* ====================================================
Media Quary
==================================================== */
/* ====================================================
// flexセット
==================================================== */
/* ====================================================
画像などを縦横比を維持したままレスポンシブ対応
*画像やGoogle Map、YouTubeの埋め込みなどを縦横比を維持したままレスポンシブ対応
.photo {
  // 画像を16:9にトリミングして縦横比を常に保つ
  @include aspect-ratio(16, 9);

  & > .image {
    @include object-fit(cover);
  }
}
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}
.mb0 {
  margin-bottom: 0 !important;
}

body {
  color: black;
  font-family: "Noto Sans JP", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ  Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
}

a {
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover {
  opacity: 0.7;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a {
    display: inline-block;
  }
}
@media screen and (min-width: 835px) {
  a[href^=tel] {
    pointer-events: none;
  }
}

.clearfix {
  *zoom: 1;
}
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}
@media screen and (max-width: 1245px) {
  .pc-xl {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .pc-ipd {
    display: none !important;
  }
}
@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}
@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}
@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .sp-ipd {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}
@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.u-fn-hover {
  display: inline-block;
}
@media screen and (min-width: 835px) {
  .u-fn-hover {
    transition: all 0.2s;
  }
  .u-fn-hover:hover {
    opacity: 0.7;
  }
}

.u-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.u-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

/* ====================================================
Control
==================================================== */
.c-fc--white {
  color: white !important;
}
.c-fc--black {
  color: black !important;
}

.c-ft--jp {
  font-family: "Noto Sans JP", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ  Ｐゴシック", "MS PGothic", sans-serif;
}

.c-fw--lt {
  font-weight: 300 !important;
}
.c-fw--rg {
  font-weight: 400 !important;
}
.c-fw--md {
  font-weight: 500 !important;
}
.c-fw--sbd {
  font-weight: 600 !important;
}
.c-fw--bd {
  font-weight: 700 !important;
}
.c-fw--bk {
  font-weight: 900 !important;
}

.c-tx--v {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
@media screen and (min-width: 835px) {
  .c-tx--v-pc {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}

.c-al--c {
  text-align: center !important;
}
.c-al--r {
  text-align: right !important;
}
.c-al--l {
  text-align: left !important;
}

.c-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.c-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

/* ====================================================
Component
==================================================== */
.c-txt__01 {
  font-size: 2rem;
  line-height: 1.8;
  font-weight: 700;
}
@media screen and (max-width: 1245px) {
  .c-txt__01 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .c-txt__01 {
    font-size: 1.3rem;
    line-height: 1.6923076923;
  }
}
.c-txt__02 {
  font-size: 1.6rem;
  line-height: 2;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .c-txt__02 {
    font-size: 1.3rem;
    line-height: 1.6923076923;
  }
}
.c-txt__03 {
  font-size: 1.6rem;
  line-height: 1.8;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .c-txt__03 {
    font-size: 1.3rem;
    line-height: 1.6923076923;
  }
}

.c-ttl__01 {
  font-size: 5.2rem;
  line-height: 1.8;
  font-weight: 700;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .c-ttl__01 {
    font-size: 4rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__01 {
    font-size: 2.4rem;
    line-height: 1.5;
  }
}
.c-ttl__02 {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 1.8;
}
@media screen and (max-width: 834px) {
  .c-ttl__02 {
    font-size: 2rem;
    line-height: 1.8;
  }
}
.c-ttl__03 {
  font-size: 3.2rem;
  line-height: 1.4375;
  font-weight: 700;
  text-align: center;
  margin-bottom: 79px;
}
@media screen and (max-width: 834px) {
  .c-ttl__03 {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
}
.c-ttl__03 span {
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 3px solid #3979F6;
}
.c-ttl__04 {
  padding-bottom: 35px;
  margin-bottom: 80px;
  font-size: 4.8rem;
  line-height: 1.95;
  font-weight: 700;
  position: relative;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .c-ttl__04 {
    margin-bottom: 30px;
    padding-bottom: 10px;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 374px) {
  .c-ttl__04 {
    font-size: 2.2rem;
  }
}
.c-ttl__04:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 200px;
  height: 8px;
  background: #3979F6;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 834px) {
  .c-ttl__04:after {
    height: 4px;
  }
}
.c-ttl__05 {
  font-weight: bold;
}
.c-ttl__05 > * {
  display: block;
}
.c-ttl__05 .en {
  font-size: 1.3rem;
  color: #0079FF;
  margin-bottom: 6px;
}
@media screen and (max-width: 834px) {
  .c-ttl__05 .en {
    font-size: 1.2rem;
  }
}
.c-ttl__05 .jp {
  font-size: 2.4rem;
}
@media screen and (max-width: 834px) {
  .c-ttl__05 .jp {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 374px) {
  .c-ttl__05 .jp {
    font-size: 1.5rem;
  }
}

.c-btn__01 {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 365px;
  height: 83px;
  background: white;
  color: black;
  border-radius: 50px;
  font-weight: bold;
  font-size: 2.4rem;
  padding: 5px 70px;
  margin: 0 auto;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .c-btn__01 {
    max-width: 212px;
    height: 48px;
    font-size: 1.3rem;
    padding: 5px 40px;
  }
}
.c-btn__01:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-left: 16px solid black;
  border-bottom: 9px solid transparent;
  top: 33px;
  right: 47px;
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .c-btn__01:after {
    border-top: 6px solid transparent;
    border-left: 8px solid #000;
    border-bottom: 6px solid transparent;
    top: 19px;
    right: 28px;
  }
}
.c-btn__01:hover {
  opacity: 1;
  background: black;
  color: white;
}
.c-btn__01:hover:after {
  border-left-color: white;
  right: 42px;
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .c-btn__01:hover:after {
    right: 23px;
  }
}
.c-btn__01--txt {
  font-size: 2rem;
  line-height: 1.8;
  padding: 0 35px 0 0;
  width: auto;
  height: auto;
  border-radius: 0;
  border: 0;
  float: right;
}
@media screen and (max-width: 834px) {
  .c-btn__01--txt {
    font-size: 1.5rem;
  }
}
.c-btn__01--txt::after {
  right: 11px;
  top: 13px;
  border-width: 6px 0 6px 13px;
}
@media screen and (max-width: 834px) {
  .c-btn__01--txt::after {
    top: 8px;
  }
}
.c-btn__01--txt:hover {
  background: transparent;
  color: #000;
}
.c-btn__01--txt:hover::after {
  right: 5px;
  border-left-color: #000;
}
.c-btn__01--bgwhite {
  border: 2px solid currentColor;
}
.c-btn__01--black {
  color: white;
  background: black;
  border: 2px solid black;
}
.c-btn__01--black::after {
  border-left-color: currentColor;
}
.c-btn__01--black:hover {
  color: black;
  border-color: currentColor;
  background: white;
}
.c-btn__01--black:hover:after {
  border-left-color: currentColor;
}
.c-btn__01--gradient {
  background: linear-gradient(90deg, #DBAAB6, #3979F6);
  border: 2px solid;
  color: white;
}
.c-btn__01--gradient::after {
  border-left-color: currentColor;
}
.c-btn__01--gradient:hover {
  color: #3979F6;
  border-color: currentColor;
  background: white;
}
.c-btn__01--gradient:hover:after {
  border-left-color: currentColor;
}
.c-btn__scroll {
  position: absolute;
  color: black;
  font-weight: 500;
  font-size: 2rem;
  padding-bottom: 30px;
  bottom: 42px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-animation: arrowmove 1s ease-in-out infinite;
  animation: arrowmove 1s ease-in-out infinite;
}
@media screen and (max-width: 834px) {
  .c-btn__scroll {
    display: none;
  }
}
.c-btn__scroll:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 24px solid black;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.c-btn__02 {
  max-width: 209px;
  width: 100%;
  height: 48px;
  border: 1px solid #000;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
  border-radius: 42px;
  color: #fff;
  font-weight: bold;
  position: relative;
  margin: 0 auto;
  font-size: 1.3rem;
}
@media screen and (max-width: 834px) {
  .c-btn__02 {
    font-size: 1.2rem;
  }
}
.c-btn__02:before {
  content: "";
  position: absolute;
  background: url("/img/index/icon-link-white.svg") center no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  transition: 0.3s ease;
}
.c-btn__02:after {
  content: "";
  position: absolute;
  background: url("/img/index/arrow-white.svg") center no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  top: 13px;
  right: 17px;
  transition: 0.3s ease;
}
.c-btn__02:hover {
  opacity: 1;
}
@media screen and (min-width: 835px) {
  .c-btn__02:hover {
    background: white;
    color: black;
  }
  .c-btn__02:hover:before {
    background: url("/img/index/icon-link-black.svg") center no-repeat;
    background-size: contain;
    transition: 0.3s ease;
  }
  .c-btn__02:hover:after {
    background: url("/img/index/arrow-black.svg") center no-repeat;
    background-size: contain;
    transition: 0.3s ease;
    top: 11px;
    right: 15px;
  }
}

.js-scrollin {
  opacity: 0;
  transform: translate(0, 70px);
}
.js-scrollin.active {
  opacity: 1;
  transform: translate(0, 0);
  transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@-webkit-keyframes arrowmove {
  0% {
    bottom: 42px;
  }
  50% {
    bottom: 52px;
  }
  100% {
    bottom: 42px;
  }
}
@keyframes arrowmove {
  0% {
    bottom: 42px;
  }
  50% {
    bottom: 52px;
  }
  100% {
    bottom: 42px;
  }
}
.inner {
  display: block;
  max-width: 1270px;
  padding: 0 20px;
  margin: 0 auto;
}
.inner--1366 {
  max-width: 1406px;
}
.inner--1045 {
  max-width: 1085px;
}

.form-control {
  display: block;
  width: 100%;
  height: 77px;
  background: #fff;
  border: 2px solid black;
  font-weight: 500;
  padding: 5px 30px;
  font-size: 2.4rem;
  line-height: 1.5;
  border-radius: 8px;
  outline: none;
  resize: none;
  appearance: none;
}
.form-control::-ms-expand {
  display: none;
}
@media screen and (max-width: 834px) {
  .form-control {
    height: 50px;
    padding: 5px 10px;
    font-size: 1.3rem;
  }
}
.form-control--txt {
  height: 414px;
}
@media screen and (max-width: 834px) {
  .form-control--txt {
    height: 200px;
  }
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.pure-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.pure-list__input {
  position: relative;
}
.pure-list__input input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.pure-list__input input[type=checkbox] + span:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}
.pure-list__input input[type=checkbox]:checked + span:after {
  content: "";
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}
.pure-list__input input[type=checkbox]:checked + span:before {
  animation: borderscale 200ms ease-in;
  background: #4F8196;
}
.pure-list__input input[type=radio] + span:after, .pure-list__input input[type=radio] + span:before {
  border-radius: 50%;
}
.pure-list__input input[type=radio]:checked + span:before {
  animation: borderscale 300ms ease-in;
  background-color: white;
}
.pure-list__input input[type=radio]:checked + span:after {
  transform: scale(1);
}
.pure-list__input span {
  display: block;
  position: relative;
  padding: 6px 99px 6px 32px;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.pure-list__input span:before {
  box-sizing: content-box;
  content: "";
  color: #4F8196;
  position: absolute;
  top: 50%;
  left: 0;
  width: 21px;
  height: 21px;
  margin-top: -13px;
  border: 2px solid black;
  text-align: center;
  transition: all 0.4s ease;
}
.pure-list__input span:after {
  box-sizing: content-box;
  content: "";
  background-color: #4F8196;
  position: absolute;
  top: 50%;
  left: 5px;
  width: 15px;
  height: 15px;
  margin-top: -8px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.fade {
  opacity: 0;
  transition: all 1.75s;
  transform: translateY(50px);
}
.fade.on {
  transition: all 1.75s;
  opacity: 1;
  transform: translateY(0);
}

#header {
  position: fixed;
  width: 100%;
  max-width: 1202px;
  background: white;
  border-bottom-right-radius: 20px;
  padding: 50px 0 36px 80px;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: 0.3s ease;
}
@media screen and (max-width: 1245px) {
  #header {
    padding: 50px 0 36px;
  }
}
@media screen and (max-width: 1024px) {
  #header {
    border-radius: 0;
    padding: 9px 0;
  }
}
@media screen and (max-width: 834px) {
  #header {
    background: transparent;
  }
}
#header.js-scrolled {
  background: white;
}
@media screen and (min-width: 835px) {
  #header .inner {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
#header .header-logo {
  display: block;
  width: 216px;
}
@media screen and (max-width: 834px) {
  #header .header-logo {
    width: 107px;
  }
}
#header .header-logo:hover {
  opacity: 0.4;
}
#header .header-nav {
  width: calc(100% - 245px);
  padding-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  #header .header-nav {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 834px) {
  #header .header-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.37);
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    overflow-y: scroll;
    transition: 0.3s ease;
  }
  #header .header-nav.active {
    opacity: 1;
    z-index: 1;
    pointer-events: auto;
    transition: 0.3s ease;
  }
  #header .header-nav.active .header-nav__menu {
    right: 0;
    transition: 0.3s ease;
  }
}
#header .header-nav__menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  #header .header-nav__menu {
    position: absolute;
    display: block;
    width: 67.7%;
    min-height: 100vh;
    background: white;
    padding-top: 68px;
    top: 0;
    right: -100%;
    transition: 0.3s ease;
  }
}
#header .header-nav__menu-item {
  margin-right: 33px;
}
@media screen and (max-width: 834px) {
  #header .header-nav__menu-item {
    border-top: 1px solid black;
    margin: 0 0 0 66px;
  }
}
@media screen and (max-width: 374px) {
  #header .header-nav__menu-item {
    margin-left: 25px;
  }
}
#header .header-nav__menu-item:nth-last-of-type(2) {
  margin-right: 48px;
}
@media screen and (max-width: 1245px) {
  #header .header-nav__menu-item:nth-last-of-type(2) {
    margin-right: 0;
  }
}
#header .header-nav__menu-item > a {
  display: block;
  font-weight: bold;
  color: black;
  font-size: 2rem;
}
@media screen and (max-width: 1245px) {
  #header .header-nav__menu-item > a {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 834px) {
  #header .header-nav__menu-item > a {
    padding: 16px 15px 15px 0;
  }
}
#header .header-nav__menu-item > a:hover {
  font-weight: 600;
  color: #4C94F7;
}
#header .header-nav__menu-btn {
  margin-top: 17px;
}
#header .header-nav__menu-btn > a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 171px;
  height: 48px;
  background: linear-gradient(90deg, #DBAAB6, #3979F6);
  color: white;
  border-radius: 50px;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0 auto;
}
#header .header-nav__menu-btn > a span {
  position: relative;
  padding-right: 10px;
}
#header .header-nav__menu-btn > a span:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 9px solid #fff;
  border-bottom: 6px solid transparent;
  top: 3px;
  right: -7px;
}
#header .js-mobile {
  width: 28px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 12;
  display: none;
}
@media screen and (max-width: 834px) {
  #header .js-mobile {
    display: block;
  }
}
#header .js-mobile span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  width: 100%;
  height: 2px;
  background: #7B8288;
}
#header .js-mobile span:before, #header .js-mobile span:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #7B8288;
  transition: transform 0.3s;
}
#header .js-mobile span:before {
  top: -9px;
}
#header .js-mobile span:after {
  bottom: -9px;
}
#header .js-mobile--close {
  right: 16px;
}
#header .js-mobile--close span {
  background-color: transparent;
}
#header .js-mobile--close span:before, #header .js-mobile--close span:after {
  width: 100%;
}
#header .js-mobile--close span:before {
  transform: rotate(45deg);
  top: 2px;
}
#header .js-mobile--close span:after {
  transform: rotate(-45deg);
  bottom: -2px;
}

.js-locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}
.js-locked body {
  -webkit-overflow-scrolling: auto;
}

.sec-contact {
  background: linear-gradient(90deg, #3979F6, #DBAAB6);
  color: white;
  padding: 77px 0 59px;
}
@media screen and (max-width: 834px) {
  .sec-contact {
    background: linear-gradient(45deg, #3979F6, #DBAAB6);
    padding: 73px 0 76px;
  }
}
.sec-contact .c-txt__01 {
  margin-bottom: 52px;
}
@media screen and (max-width: 834px) {
  .sec-contact .c-txt__01 {
    margin-bottom: 25px;
  }
}

.contact-ttl {
  text-align: center;
  font-weight: bold;
  font-size: 3.6rem;
  margin-bottom: 31px;
}
@media screen and (max-width: 1245px) {
  .contact-ttl {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 834px) {
  .contact-ttl {
    font-size: 2rem;
    line-height: 1.8;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 374px) {
  .contact-ttl {
    font-size: 1.8rem;
  }
}

.contact-txt {
  max-width: 526px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 500;
  font-size: 2.4rem;
  margin: 0 auto 56px;
}
@media screen and (max-width: 834px) {
  .contact-txt {
    max-width: 307px;
    margin-bottom: 25px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 374px) {
  .contact-txt {
    font-size: 1.3rem;
  }
}
.contact-txt span {
  border-bottom: 1px solid white;
  padding-bottom: 4px;
}
@media screen and (max-width: 834px) {
  .contact-txt span {
    padding-bottom: 2px;
  }
}
.contact-txt span a {
  color: white;
  margin-left: 4px;
}
@media screen and (max-width: 834px) {
  .contact-txt span a {
    margin-left: 3px;
  }
}

.js-backtop {
  position: absolute;
  width: 51px;
  height: 51px;
  border-radius: 50%;
  border: 1px solid black;
  top: -6px;
  right: -2px;
}
@media screen and (max-width: 1440px) {
  .js-backtop {
    top: 5px;
    right: 20px;
  }
}
@media screen and (max-width: 834px) {
  .js-backtop {
    width: 29px;
    height: 29px;
  }
}
.js-backtop:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 19px solid black;
  top: 14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .js-backtop:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid #000;
    top: 6px;
  }
}
.js-backtop:hover {
  opacity: 1;
}
.js-backtop:hover:after {
  top: 7px;
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .js-backtop:hover:after {
    top: 3px;
  }
}

#footer {
  padding: 123px 0 100px;
}
@media screen and (max-width: 834px) {
  #footer {
    padding: 50px 0;
  }
}
#footer .inner {
  position: relative;
  max-width: 1407px;
}
@media screen and (max-width: 640px) {
  #footer .inner {
    padding: 0 40px;
  }
}
#footer .footer-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 71px;
}
@media screen and (max-width: 640px) {
  #footer .footer-wrap {
    max-width: 295px;
    margin: 0 auto 46px;
  }
}
#footer .footer-wrap__logo {
  width: 294px;
}
@media screen and (max-width: 1024px) {
  #footer .footer-wrap__logo {
    width: 155px;
  }
}
#footer .footer-wrap__logo img {
  width: 100%;
}
#footer .footer-wrap__list {
  width: calc(100% - 397px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
}
@media screen and (max-width: 1024px) {
  #footer .footer-wrap__list {
    width: calc(100% - 200px);
    padding-top: 0;
  }
}
@media screen and (max-width: 640px) {
  #footer .footer-wrap__list {
    width: 100%;
    margin-top: 17px;
  }
}
#footer .footer-wrap__list-item {
  margin-right: 127px;
}
@media screen and (max-width: 1024px) {
  #footer .footer-wrap__list-item {
    margin-right: 80px;
  }
}
@media screen and (max-width: 640px) {
  #footer .footer-wrap__list-item {
    margin-right: 35px;
  }
}
#footer .footer-wrap__list-item a {
  display: block;
  color: black;
  font-size: 1.6rem;
  margin-bottom: 6px;
}
@media screen and (max-width: 834px) {
  #footer .footer-wrap__list-item a {
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 4px;
  }
}
#footer .footer-wrap__list-item a:last-of-type {
  margin-bottom: 0;
}
#footer .footer-copy {
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  #footer .footer-copy {
    font-weight: 300;
    font-size: 1.3rem;
  }
}

.sec-mv {
  background: #EDF3F5;
  padding-top: 207px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .sec-mv {
    padding-top: 52px;
  }
}
.sec-mv .inner {
  position: relative;
}
.sec-mv .c-btn__scroll {
  left: 42px;
}

.mv-wrap {
  position: relative;
}
.mv-wrap__ct {
  position: absolute;
  width: 560px;
  top: 44.6%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .mv-wrap__ct {
    position: relative;
    width: 100%;
    top: 0;
    transform: unset;
    margin: -21px 0 23px;
  }
}
.mv-wrap__ct-ttl {
  font-weight: bold;
  font-size: 8rem;
  line-height: 1.35;
  margin-bottom: 40px;
}
@media screen and (max-width: 1245px) {
  .mv-wrap__ct-ttl {
    font-size: 5.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .mv-wrap__ct-ttl {
    font-size: 4rem;
    line-height: 1.4;
    margin-bottom: 20px;
  }
}
.mv-wrap__ct-txt {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.75;
}
@media screen and (max-width: 1245px) {
  .mv-wrap__ct-txt {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .mv-wrap__ct-txt {
    font-size: 1.6rem;
    line-height: 1.625;
  }
}
.mv-wrap__img {
  position: relative;
  width: calc(100% - 502px);
  margin-left: auto;
}
@media screen and (max-width: 1245px) {
  .mv-wrap__img {
    width: calc(100% - 315px);
  }
}
@media screen and (max-width: 1024px) {
  .mv-wrap__img {
    width: calc(100% - 210px);
  }
}
@media screen and (max-width: 834px) {
  .mv-wrap__img {
    position: relative;
    width: 100%;
    left: -9px;
  }
}
.mv-wrap__img span {
  position: relative;
  width: auto;
  display: block;
  margin-right: -177px;
}
@media screen and (max-width: 1720px) {
  .mv-wrap__img span {
    margin-right: calc((1366px - 100vw) / 2);
  }
}
@media screen and (max-width: 1720px) {
  .mv-wrap__img span {
    margin-right: calc((1366px - 100vw) / 2);
  }
}
@media screen and (max-width: 1406px) {
  .mv-wrap__img span {
    margin-right: -20px;
  }
}
.mv-wrap__img span img {
  width: 100%;
}
.mv-wrap__img-txt {
  position: absolute;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #B1C3CB;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.7777777778;
}
@media screen and (max-width: 640px) {
  .mv-wrap__img-txt {
    font-size: 1.2rem;
  }
}
.mv-wrap__img-txt:first-of-type {
  width: 236px;
  height: 227px;
  padding: 0 14px 21px 0;
  top: 6%;
  left: 11.8%;
}
@media screen and (max-width: 1770px) {
  .mv-wrap__img-txt:first-of-type {
    top: -3%;
    left: 6.8%;
  }
}
@media screen and (max-width: 1024px) {
  .mv-wrap__img-txt:first-of-type {
    top: -9%;
  }
}
@media screen and (max-width: 640px) {
  .mv-wrap__img-txt:first-of-type {
    width: 121px;
    height: 116px;
    padding: 40px 0 0;
    top: auto;
    bottom: 29px;
    left: -25px;
  }
}
@media screen and (max-width: 374px) {
  .mv-wrap__img-txt:first-of-type {
    bottom: 4px;
    left: -14px;
  }
}
.mv-wrap__img-txt:nth-of-type(2) {
  width: 267px;
  height: 233px;
  padding: 0 15px 6px 0;
  top: -1.8%;
  right: 3%;
}
@media screen and (max-width: 1440px) {
  .mv-wrap__img-txt:nth-of-type(2) {
    top: -5.8%;
  }
}
@media screen and (max-width: 1024px) {
  .mv-wrap__img-txt:nth-of-type(2) {
    top: -9.8%;
  }
}
@media screen and (max-width: 640px) {
  .mv-wrap__img-txt:nth-of-type(2) {
    width: 137px;
    height: 119px;
    top: -5px;
    right: -54px;
    padding: 0 22px 8px 0;
  }
}
@media screen and (max-width: 374px) {
  .mv-wrap__img-txt:nth-of-type(2) {
    top: -19px;
  }
}
.mv-wrap__img-txt:nth-of-type(3) {
  width: 237px;
  height: 173px;
  bottom: 19.3%;
  left: 23.5%;
}
@media screen and (max-width: 1440px) {
  .mv-wrap__img-txt:nth-of-type(3) {
    bottom: 13.3%;
  }
}
@media screen and (max-width: 640px) {
  .mv-wrap__img-txt:nth-of-type(3) {
    width: 121px;
    height: 89px;
    bottom: -132px;
    left: auto;
    right: -62px;
    padding-right: 20px;
  }
}
.mv-wrap__img-txt canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.mv-wrap__img-txt p {
  position: relative;
  z-index: 1;
}

.mv-btn {
  position: fixed;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 437px;
  height: 140px;
  background: linear-gradient(90deg, #DBAAB6, #3979F6);
  color: white;
  border-top-left-radius: 20px;
  font-weight: bold;
  font-size: 2.4rem;
  right: 0;
  bottom: 0;
  z-index: 10;
}
@media screen and (max-width: 834px) {
  .mv-btn {
    margin-top: 0;
    width: 100%;
    max-width: 187px;
    height: 53px;
    font-size: 1.3rem;
    padding: 6px 13px 0 0;
  }
}
.mv-btn:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 16px solid #fff;
  top: 62px;
  right: 85px;
}
@media screen and (max-width: 834px) {
  .mv-btn:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #fff;
    top: 25px;
    right: 21px;
  }
}

.mv-news {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% - 70px);
  max-width: 981px;
  background: black;
  color: white;
  border-top-left-radius: 20px;
  font-weight: 500;
  font-size: 1.6rem;
  margin: -64px 0 0 auto;
  padding: 28px 30px 20px;
}
@media screen and (max-width: 834px) {
  .mv-news {
    width: calc(100% + 40px);
    border-radius: 0;
    font-size: 1.3rem;
    margin: 0 -20px;
    padding: 14px 20px 17px;
  }
}
.mv-news:after {
  content: "";
  position: absolute;
  background: black;
  width: calc((100vw - 1366px) / 2);
  height: 100%;
  top: 0;
  left: 100%;
}
@media screen and (max-width: 1406px) {
  .mv-news:after {
    width: 20px;
  }
}
@media screen and (max-width: 834px) {
  .mv-news:after {
    display: none;
  }
}
.mv-news__tag {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 117px;
  height: 44px;
  border-radius: 40px;
  border: 1px solid white;
}
@media screen and (max-width: 834px) {
  .mv-news__tag {
    width: 74px;
    height: 26px;
  }
}
.mv-news__txt {
  width: calc(100% - 295px);
  padding: 0 27px 4px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 27px;
}
@media screen and (max-width: 834px) {
  .mv-news__txt {
    width: calc(100% - 151px);
    max-height: 22px;
    padding: 2px 14px 0;
  }
}
.mv-news__btn {
  position: relative;
  width: 99px;
  color: white;
  padding-bottom: 4px;
}
@media screen and (max-width: 834px) {
  .mv-news__btn {
    width: 77px;
    padding-bottom: 2px;
  }
}
.mv-news__btn:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 12px solid #fff;
  border-bottom: 7px solid transparent;
  top: 5px;
  right: 0;
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .mv-news__btn:after {
    border-top: 5px solid transparent;
    border-left: 8px solid #fff;
    border-bottom: 5px solid transparent;
    top: 4px;
    right: 2px;
  }
}
.mv-news__btn:hover {
  opacity: 1;
  text-decoration: underline;
}
.mv-news__btn:hover:after {
  right: -5px;
  transition: 0.3s ease;
}

.sec-intro {
  padding: 136px 0 218px;
}
@media screen and (max-width: 834px) {
  .sec-intro {
    padding: 47px 0 77px;
  }
}

.intro-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 59px;
}
@media screen and (max-width: 834px) {
  .intro-wrap {
    margin-bottom: 26px;
  }
}
.intro-wrap .c-ttl__01 {
  width: 500px;
  text-align: left;
}
@media screen and (max-width: 1024px) {
  .intro-wrap .c-ttl__01 {
    width: 375px;
  }
}
@media screen and (max-width: 834px) {
  .intro-wrap .c-ttl__01 {
    text-align: center;
    width: 100%;
  }
}
.intro-wrap__txt {
  width: calc(100% - 575px);
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.09;
  padding-top: 11px;
}
@media screen and (max-width: 1024px) {
  .intro-wrap__txt {
    width: calc(100% - 375px);
  }
}

.intro-block {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 20px 0 0 20px;
  border: solid black;
  border-width: 2px 0 2px 2px;
  padding: 55px 0 39px;
}
@media screen and (max-width: 834px) {
  .intro-block {
    border-width: 2px;
    border-radius: 20px;
    padding: 38px 17px 7px;
  }
}
.intro-block:after {
  content: "";
  position: absolute;
  width: calc((100vw - 1366px) / 2);
  height: 100%;
  border: solid black;
  border-width: 2px 0;
  top: -2px;
  left: 100%;
}
@media screen and (max-width: 1406px) {
  .intro-block:after {
    width: 20px;
  }
}
@media screen and (max-width: 834px) {
  .intro-block:after {
    display: none;
  }
}
.intro-block--also:before {
  content: "";
  position: absolute;
  background: url("/img/index/also-shape.png") center no-repeat;
  background-size: contain;
  width: 240px;
  height: 263px;
  top: -213px;
  left: 16.2%;
  transform: translateX(-50%);
}
@media screen and (max-width: 1245px) {
  .intro-block--also:before {
    width: 131px;
    height: 143px;
    top: -117px;
    left: 12.2%;
  }
}
@media screen and (max-width: 640px) {
  .intro-block--also:before {
    left: 23.1%;
  }
}
.intro-block--also .intro-block__logo {
  padding: 48px 0 0 67px;
}
@media screen and (max-width: 834px) {
  .intro-block--also .intro-block__logo {
    padding: 11px 0 22px;
  }
}
.intro-block__logo {
  text-align: center;
  width: 40%;
  padding-top: 26px;
}
@media screen and (max-width: 834px) {
  .intro-block__logo {
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.75;
    padding-top: 0;
    margin-bottom: 16px;
  }
  .intro-block__logo p {
    text-align: left;
    margin-top: 28px;
  }
}
.intro-block__list {
  width: 58.1%;
}
@media screen and (max-width: 834px) {
  .intro-block__list {
    width: 100%;
  }
}
.intro-block__list-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 632px;
  border-bottom: 1px solid black;
  padding: 9px 0;
}
@media screen and (max-width: 834px) {
  .intro-block__list-item {
    max-width: 100%;
    padding: 8px 0 9px;
  }
}
.intro-block__list-item:last-of-type {
  border-bottom: 0;
}
.intro-block__list-ttl {
  width: 142px;
  font-size: 1.6rem;
  padding-top: 5px;
}
@media screen and (max-width: 834px) {
  .intro-block__list-ttl {
    width: 93px;
    font-size: 1.3rem;
    padding-top: 2px;
  }
}
.intro-block__list-txt {
  width: calc(100% - 142px);
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .intro-block__list-txt {
    width: calc(100% - 93px);
  }
}
.intro-block__ct {
  width: 56.4%;
  padding-top: 42px;
}
@media screen and (max-width: 834px) {
  .intro-block__ct {
    width: 100%;
    font-weight: 500;
    padding-top: 0;
  }
}
.intro-block__ct-ttl {
  font-weight: bold;
  font-size: 5.2rem;
  margin-bottom: 33px;
  line-height: 1;
}
@media screen and (max-width: 834px) {
  .intro-block__ct-ttl {
    margin-bottom: 21px;
  }
  .intro-block__ct-ttl span {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .intro-block__ct-ttl {
    margin-right: -10px;
  }
}
.intro-block__ct-ttl small {
  display: block;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 2.8rem;
  margin-bottom: 9px;
}
@media screen and (max-width: 834px) {
  .intro-block__ct-ttl small {
    font-size: 2rem;
  }
}
.intro-block__ct-btn {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 382px;
  height: 105px;
  background: black;
  color: white;
  border: 3px solid black;
  border-radius: 50px;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 1.2727272727;
  margin: 28px 0 11px auto;
  padding: 5px 30px;
}
@media screen and (max-width: 834px) {
  .intro-block__ct-btn {
    max-width: 222px;
    height: 61px;
    font-size: 1.3rem;
    line-height: 1.5384615385;
    margin: 29px auto;
  }
}
.intro-block__ct-btn:before {
  content: "";
  position: absolute;
  background: url("/img/index/icon-link-white.svg") center no-repeat;
  background-size: contain;
  width: 28px;
  height: 28px;
  top: 34px;
  right: 44px;
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .intro-block__ct-btn:before {
    width: 15px;
    height: 15px;
    top: 20px;
    right: 24px;
  }
}
.intro-block__ct-btn:after {
  content: "";
  position: absolute;
  background: url("/img/index/arrow-white.svg") center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
  top: 28px;
  right: 39px;
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .intro-block__ct-btn:after {
    width: 12px;
    height: 12px;
    top: 17px;
    right: 21px;
  }
}
.intro-block__ct-btn:hover {
  opacity: 1;
}
@media screen and (min-width: 835px) {
  .intro-block__ct-btn:hover {
    background: white;
    color: black;
  }
  .intro-block__ct-btn:hover:before {
    background: url("/img/index/icon-link-black.svg") center no-repeat;
    background-size: contain;
    transition: 0.3s ease;
  }
  .intro-block__ct-btn:hover:after {
    background: url("/img/index/arrow-black.svg") center no-repeat;
    background-size: contain;
    transition: 0.3s ease;
    top: 24px;
    right: 35px;
  }
}
@media screen and (min-width: 835px) and (max-width: 834px) {
  .intro-block__ct-btn:hover:after {
    top: 14px;
    right: 18px;
  }
}

.sec-trouble {
  background: #EDF3F5;
  padding: 74px 0 161px;
}
@media screen and (max-width: 834px) {
  .sec-trouble {
    padding: 36px 0 111px;
  }
}

.trouble-list {
  margin: 0 -14px;
}
@media screen and (max-width: 1440px) {
  .trouble-list {
    margin: 0 50px;
  }
}
@media screen and (max-width: 640px) {
  .trouble-list {
    margin: 0;
  }
}
.trouble-list .slick-track {
  display: flex !important;
}
.trouble-list .slick-slide {
  height: inherit !important;
}
.trouble-list .slick-prev, .trouble-list .slick-next {
  top: 49%;
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
  transition: 0.3s ease;
  z-index: 1;
}
@media screen and (max-width: 640px) {
  .trouble-list .slick-prev, .trouble-list .slick-next {
    top: 45%;
    width: 30px;
    height: 30px;
  }
}
.trouble-list .slick-prev:before, .trouble-list .slick-next:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 17px solid #edf3f5;
  border-bottom: 10px solid transparent;
  top: 16px;
}
@media screen and (max-width: 640px) {
  .trouble-list .slick-prev:before, .trouble-list .slick-next:before {
    border-top: 6px solid transparent;
    border-left: 10px solid #edf3f5;
    border-bottom: 6px solid transparent;
    top: 9px;
  }
}
.trouble-list .slick-prev:hover, .trouble-list .slick-next:hover {
  opacity: 0.7;
  transition: 0.3s ease;
}
.trouble-list .slick-next {
  right: -78px;
}
@media screen and (max-width: 1245px) {
  .trouble-list .slick-next {
    right: -50px;
  }
}
@media screen and (max-width: 640px) {
  .trouble-list .slick-next {
    right: 0;
  }
}
@media screen and (max-width: 374px) {
  .trouble-list .slick-next {
    right: -15px;
  }
}
.trouble-list .slick-next:before {
  right: 15px;
}
@media screen and (max-width: 640px) {
  .trouble-list .slick-next:before {
    right: 8px;
  }
}
.trouble-list .slick-prev {
  left: -78px;
}
@media screen and (max-width: 1245px) {
  .trouble-list .slick-prev {
    left: -50px;
  }
}
@media screen and (max-width: 640px) {
  .trouble-list .slick-prev {
    left: 0;
  }
}
@media screen and (max-width: 374px) {
  .trouble-list .slick-prev {
    left: -15px;
  }
}
.trouble-list .slick-prev:before {
  transform: scaleX(-1);
  left: 15px;
}
@media screen and (max-width: 640px) {
  .trouble-list .slick-prev:before {
    left: 8px;
  }
}
.trouble-list .slick-dots {
  bottom: -52px;
  left: -1px;
}
.trouble-list .slick-dots li {
  margin: 0 8px;
}
.trouble-list .slick-dots li button:before {
  width: 11px;
  height: 11px;
  background: #ACC4CC;
}
.trouble-list .slick-dots li.slick-active button:before {
  border: 0;
  background: #798289;
}
.trouble-list__item {
  position: relative;
  background: white;
  border-radius: 20px;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.8;
  padding: 45px 37px;
  margin: 0 14px;
  cursor: pointer;
  transition: 0.3s ease;
}
@media screen and (max-width: 1440px) {
  .trouble-list__item {
    padding: 30px 22px 18px;
  }
}
@media screen and (max-width: 834px) {
  .trouble-list__item {
    font-size: 1.3rem;
    line-height: 1.6923076923;
  }
}
@media screen and (max-width: 640px) {
  .trouble-list__item {
    margin: 0 40px;
  }
}
@media screen and (max-width: 374px) {
  .trouble-list__item {
    margin: 0 20px;
  }
}
.trouble-list__item a {
  display: block;
  color: black;
}
.trouble-list__item a > * {
  position: relative;
}
.trouble-list__item a:hover {
  opacity: 1;
}
.trouble-list__item:before {
  content: "";
  position: absolute;
  background: white;
  width: calc(100% - 4px);
  height: calc(100% - 16px);
  border: 2px solid black;
  border-radius: 20px;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s ease;
}
.trouble-list__item:hover {
  background: black;
  transition: 0.3s ease;
}
.trouble-list__item:hover:before {
  opacity: 1;
  transition: 0.3s ease;
}
.trouble-list__img {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 38px;
}
@media screen and (max-width: 640px) {
  .trouble-list__img {
    height: 170px !important;
    margin-bottom: 13px;
  }
}
.trouble-list__ttl {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.5;
  margin-bottom: 23px;
}
@media screen and (max-width: 1245px) {
  .trouble-list__ttl {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .trouble-list__ttl {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 374px) {
  .trouble-list__ttl {
    font-size: 1.5rem;
  }
}

.sec-features {
  padding: 71px 0 149px;
}
@media screen and (max-width: 834px) {
  .sec-features {
    padding: 40px 0 60px;
  }
  .sec-features .c-txt__01 {
    font-weight: 500;
  }
  .sec-features .c-btn__01 {
    max-width: 195px;
    height: 54px;
  }
}

.features-wrap {
  margin-bottom: 42px;
}
@media screen and (min-width: 641px) {
  .features-wrap {
    border-radius: 20px;
    border: 2px solid black;
    padding: 32px 15px 29px;
  }
}
@media screen and (max-width: 640px) {
  .features-wrap {
    overflow-x: scroll;
    margin-right: -20px;
    padding-right: 20px;
    margin-bottom: 25px;
  }
}

.features-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .features-list {
    width: 715px;
    border-radius: 20px;
    border: 2px solid black;
    padding: 21px 48px 25px;
  }
}
.features-list__item {
  position: relative;
  text-align: center;
  width: 30%;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.8;
}
@media screen and (max-width: 834px) {
  .features-list__item {
    width: 33%;
    font-size: 1.3rem;
    line-height: 1.6923076923;
  }
}
.features-list__item:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-left: 20px solid black;
  border-bottom: 11px solid transparent;
  top: 49.5%;
  right: -32px;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .features-list__item:after {
    right: -20px;
  }
}
@media screen and (max-width: 640px) {
  .features-list__item:after {
    border-top: 7px solid transparent;
    border-left: 13px solid #000;
    border-bottom: 7px solid transparent;
    top: 50.8%;
    right: 0;
  }
}
.features-list__item:last-of-type:after {
  display: none;
}
@media screen and (max-width: 834px) {
  .features-list__item .c-ttl__02 {
    margin-bottom: 3px;
  }
}
.features-list__img {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 7px;
}
@media screen and (max-width: 834px) {
  .features-list__img {
    padding: 0 20px;
    margin-bottom: 9px;
  }
}

.features-txt {
  text-align: center;
}
@media screen and (max-width: 834px) {
  .features-txt {
    text-align: left;
    padding: 0 20px;
  }
}

.sec-support {
  background: #EDF3F5;
  padding: 120px 0 190px;
}
@media screen and (max-width: 834px) {
  .sec-support {
    padding: 32px 0 80px;
  }
}

.support-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.support-wrap__item {
  width: 48.4%;
  background: white;
  border-radius: 20px;
  padding: 39px 70px 45px;
}
@media screen and (max-width: 1024px) {
  .support-wrap__item {
    padding: 24px 20px 25px;
  }
}
@media screen and (max-width: 640px) {
  .support-wrap__item {
    width: 100%;
    margin-bottom: 33px;
  }
  .support-wrap__item:last-of-type {
    margin-bottom: 0;
  }
}
.support-wrap__item .c-ttl__02 {
  margin-bottom: 28px;
}
@media screen and (max-width: 834px) {
  .support-wrap__item .c-ttl__02 {
    margin-bottom: 21px;
  }
}
.support-wrap__box {
  background: #EDF3F5;
  padding: 34px 40px 32px;
  margin-bottom: 21px;
}
@media screen and (max-width: 1024px) {
  .support-wrap__box {
    padding: 19px 20px 15px;
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 834px) {
  .support-wrap__box + p {
    font-weight: 500;
  }
}
.support-wrap__note {
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.8;
  margin-top: 15px;
}
@media screen and (max-width: 834px) {
  .support-wrap__note {
    font-size: 1.3rem;
    line-height: 1.6923076923;
  }
}
@media screen and (max-width: 374px) {
  .support-wrap__note {
    font-size: 1.1rem;
  }
}
.support-wrap__img {
  text-align: center;
  margin: 102px 0 110px;
}
@media screen and (max-width: 640px) {
  .support-wrap__img {
    margin: -10px 0 27px;
  }
  .support-wrap__img img {
    width: 111px;
  }
}

.sec-also {
  padding: 129px 0 164px;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .sec-also {
    padding: 48px 0 63px;
  }
}
@media screen and (max-width: 640px) {
  .sec-also .c-ttl__01 {
    transform: translateX(-50%);
    left: calc(50% + 55px);
  }
}

.page-mv {
  text-align: center;
  padding: 218px 0 127px;
  background: linear-gradient(125deg, #DBAAB6, #3979F6);
  color: white;
  position: relative;
}
@media screen and (max-width: 834px) {
  .page-mv {
    padding: 60px 0;
  }
}
.page-mv > * {
  position: relative;
  z-index: 9;
}
.page-mv:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(/img/template/bg-01.png) center no-repeat;
  background-size: 100% 100%;
  inset: 0 auto auto 0;
  z-index: 1;
}
@media screen and (max-width: 1245px) {
  .page-mv:after {
    background-size: cover;
  }
}
@media screen and (max-width: 834px) {
  .page-mv:after {
    display: none;
  }
}

.link-scroll {
  font-size: 2.4rem;
  line-height: 1.5;
  font-weight: 700;
  position: fixed;
  color: white;
  right: 0;
  width: 437px;
  height: 140px;
  border-width: 2px;
  border-radius: 20px 0 0 0;
  background: linear-gradient(90deg, #DBAAB6, #3979F6);
  bottom: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 10;
}
@media screen and (max-width: 834px) {
  .link-scroll {
    width: 200px;
    height: 70px;
    font-size: 1.6rem;
  }
}
.link-scroll:hover {
  color: #3979F6;
  border-style: solid;
  border-color: currentColor;
  background: white;
  opacity: 1;
}
.link-scroll:hover::after {
  right: 60px;
}
.link-scroll:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-left: 16px solid currentColor;
  border-bottom: 9px solid transparent;
  top: 50%;
  transform: translateY(-50%);
  right: 96px;
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .link-scroll:after {
    border-top: 6px solid transparent;
    border-left: 8px solid currentColor;
    border-bottom: 6px solid transparent;
    right: 15px;
  }
}

.sec-product {
  padding-top: 85px;
  padding-bottom: 175px;
}
@media screen and (max-width: 834px) {
  .sec-product {
    padding: 50px 0;
  }
}

.product-box {
  margin-bottom: 218px;
}
@media screen and (max-width: 834px) {
  .product-box {
    margin-bottom: 50px;
  }
}
.product-box__top {
  border-bottom: 2px solid black;
  margin-bottom: 46px;
}
.product-box__tab-ct {
  display: none;
}
.product-box__tab-ct.active {
  display: block;
}
.product-box__tab .inner {
  max-width: 1406px;
}

.product-link {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -53px;
  font-size: 2.4rem;
  line-height: 1.5;
}
@media screen and (max-width: 834px) {
  .product-link {
    margin: 0 -10px;
    font-size: 1.6rem;
  }
}
.product-link li {
  padding: 0 53px;
  margin-bottom: -2px;
  max-width: 445px;
  width: 33.33%;
}
@media screen and (max-width: 834px) {
  .product-link li {
    padding: 0 10px;
  }
}
.product-link li.active a, .product-link li a:hover, .product-link li.active span, .product-link li span:hover {
  opacity: 1;
  background: black;
  color: white;
}
.product-link li span, .product-link li a {
  text-align: center;
  padding: 19px 5px 16px;
  color: black;
  border: 2px solid black;
  border-radius: 20px 20px 0 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  transition: all 0.35s;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .product-link li span, .product-link li a {
    padding: 8px 5px;
  }
}

.product-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 69px;
  line-height: 1.8;
}
@media screen and (max-width: 1245px) {
  .product-column {
    grid-gap: 30px;
  }
}
@media screen and (max-width: 834px) {
  .product-column {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 640px) {
  .product-column {
    grid-gap: 5px;
  }
}
.product-column__item {
  display: block;
  color: black;
  border: 2px solid black;
  border-radius: 20px;
  overflow: hidden;
}
.product-column__img {
  position: relative;
  overflow: hidden;
}
.product-column__img::before {
  content: "";
  display: block;
  padding-top: 54.56%;
}
.product-column__img-thumb {
  position: absolute;
  inset: 0 auto auto 0;
  height: 100%;
  width: 100%;
}
.product-column__img-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-column__ct {
  padding: 20px 29px 11px;
}
@media screen and (max-width: 640px) {
  .product-column__ct {
    padding: 10px;
  }
}
.product-column__category {
  font-size: 1.6rem;
  font-weight: 700;
  background: black;
  color: white;
  display: inline-block;
  padding: 6px 15px;
  position: absolute;
  inset: auto auto 0 26px;
}
@media screen and (max-width: 834px) {
  .product-column__category {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 640px) {
  .product-column__category {
    left: 10px;
  }
}
.product-column__refer {
  background: #EDF3F5;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 500;
  padding: 10px 5px;
  margin-bottom: 8px;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .product-column__refer {
    font-size: 1.3rem;
  }
}
.product-column__title {
  border-bottom: 2px solid black;
  display: block;
  padding-bottom: 6px;
  margin-bottom: 14px;
  min-height: 79px;
}
@media screen and (max-width: 834px) {
  .product-column__title {
    min-height: 56px;
  }
}
.product-column__price {
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: right;
}
@media screen and (max-width: 834px) {
  .product-column__price {
    font-size: 1.3rem;
  }
}
.product-column__price span {
  font-size: 2.8rem;
}
@media screen and (max-width: 1245px) {
  .product-column__price span {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 834px) {
  .product-column__price span {
    font-size: 2rem;
  }
}

.txt-under {
  text-decoration: underline;
}

.part-info {
  margin-bottom: 157px;
}
@media screen and (max-width: 834px) {
  .part-info {
    margin-bottom: 50px;
  }
}
.part-info__img {
  margin-bottom: 40px;
}
.part-info__ct {
  margin-bottom: 44px;
}
.part-info__ct p a {
  color: #0079FF;
}

.part-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 122px;
}
@media screen and (max-width: 834px) {
  .part-block {
    margin-bottom: 50px;
  }
}
.part-block__img {
  width: calc(50% - 24px);
}
@media screen and (max-width: 834px) {
  .part-block__img {
    width: 100%;
    margin-bottom: 20px;
  }
}
.part-block__ct {
  width: calc(50% - 33px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (max-width: 834px) {
  .part-block__ct {
    width: 100%;
  }
}
.part-block__ct p a {
  color: #0079FF;
}
.part-block__ct-boxlink {
  margin-top: auto;
  text-align: right;
}
@media screen and (max-width: 834px) {
  .part-block__ct-boxlink {
    margin-top: 15px;
  }
}
.part-block--02 {
  margin-bottom: 240px;
}
@media screen and (max-width: 834px) {
  .part-block--02 {
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 835px) {
  .part-block--02 .c-block__img {
    width: calc(50% - 35px);
  }
}
@media screen and (min-width: 835px) {
  .part-block--02 .c-block__ct {
    width: calc(50% - 21px);
  }
}

.part-desc {
  font-size: 2.4rem;
  line-height: 2;
  font-weight: 500;
  margin-bottom: 166px;
}
@media screen and (max-width: 834px) {
  .part-desc {
    margin-bottom: 50px;
    font-size: 1.6rem;
    line-height: 1.875;
  }
}
.part-desc__box {
  background: #EDF3F5;
  border-radius: 20px;
  padding: 37px 62px;
}
@media screen and (max-width: 834px) {
  .part-desc__box {
    padding: 15px 30px;
  }
}
.part-desc__title {
  font-size: 2.4rem;
  line-height: 1.4583333333;
  font-weight: 700;
  text-align: center;
  margin-bottom: 33px;
}
.part-desc--02 {
  font-size: 2rem;
  line-height: 2;
  font-weight: 500;
  max-width: 1046px;
  margin: 0 auto 194px;
}
@media screen and (max-width: 834px) {
  .part-desc--02 {
    font-size: 1.3rem;
    line-height: 1.6923076923;
    margin-bottom: 50px;
  }
}
.part-desc--02 .part-desc__box {
  padding: 37px 48px;
  background: transparent;
  border: 2px solid black;
}
@media screen and (max-width: 834px) {
  .part-desc--02 .part-desc__box {
    padding: 15px 30px;
  }
}

.c-list {
  counter-reset: number;
}
.c-list__item {
  position: relative;
  padding-left: 31px;
}
.c-list__item::before {
  counter-increment: number;
  content: counter(number) ". ";
  position: absolute;
  left: 0;
}

.part-work {
  max-width: 564px;
  margin: 0 auto 194px;
}
@media screen and (max-width: 834px) {
  .part-work {
    margin-bottom: 50px;
    max-width: 100%;
  }
}
.part-work__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.part-work__item:not(:last-child) {
  padding-bottom: 10px;
  margin-bottom: 13px;
  border-bottom: 1px solid;
}
.part-work__title {
  width: 140px;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.25;
}
@media screen and (max-width: 834px) {
  .part-work__title {
    line-height: inherit;
    width: 110px;
    font-size: 1.3rem;
  }
}
.part-work__ct {
  width: calc(100% - 140px);
  font-weight: 500;
  padding-left: 3px;
}
@media screen and (max-width: 834px) {
  .part-work__ct {
    width: calc(100% - 110px);
  }
}

.part-workinfo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.part-workinfo__left {
  width: 50%;
  max-width: 559px;
  padding-right: 10px;
}
@media screen and (max-width: 834px) {
  .part-workinfo__left {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }
}
.part-workinfo__right {
  width: 50%;
  max-width: 566px;
  padding-left: 10px;
}
@media screen and (max-width: 834px) {
  .part-workinfo__right {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
  }
}

.sec-informition {
  padding-bottom: 131px;
}
@media screen and (max-width: 834px) {
  .sec-informition {
    padding-bottom: 60px;
  }
}
.sec-informition__title {
  font-size: 4.8rem;
  line-height: 1.95;
  font-weight: 700;
  text-align: center;
  margin-bottom: 7px;
}
@media screen and (max-width: 834px) {
  .sec-informition__title {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}

.part-informition {
  border: 2px solid black;
  border-radius: 20px;
  padding: 40px 92px 48px;
  margin-bottom: 158px;
}
@media screen and (max-width: 834px) {
  .part-informition {
    margin-bottom: 50px;
    padding: 15px 20px;
  }
}
.part-informition__item:not(:last-child) {
  border-bottom: 1px solid black;
  padding-bottom: 20px;
  margin-bottom: 11px;
}
.part-informition__item a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: black;
  cursor: pointer;
}
.part-informition__date {
  width: 149px;
}
@media screen and (max-width: 834px) {
  .part-informition__date {
    width: 100%;
    margin-bottom: 10px;
  }
}
.part-informition__ct {
  width: calc(100% - 149px);
  padding-left: 5px;
  font-weight: 400;
}
@media screen and (max-width: 834px) {
  .part-informition__ct {
    width: 100%;
    padding-left: 0;
  }
}

.informition-contact {
  max-width: 892px;
  margin: 0 auto 50px;
  line-height: 1.75;
}
@media screen and (max-width: 834px) {
  .informition-contact {
    margin-bottom: 30px;
  }
}
.informition-contact .required {
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #3979F6;
  text-align: center;
  width: 35px;
  height: 19px;
  margin-left: 7px;
}
.informition-contact__item {
  margin-bottom: 51px;
}
@media screen and (max-width: 834px) {
  .informition-contact__item {
    margin-bottom: 25px;
  }
}
.informition-contact__txt {
  font-size: 2.4rem;
  line-height: 1.5;
}
@media screen and (max-width: 834px) {
  .informition-contact__txt {
    font-size: 1.6rem;
  }
}
.informition-contact__title {
  display: block;
  margin-bottom: 12px;
}
.informition-contact__note {
  font-size: 1.4rem;
  margin-top: 8px;
  font-weight: 400;
}
.informition-contact__confirm {
  text-align: center;
  padding-top: 180px;
}
@media screen and (max-width: 834px) {
  .informition-contact__confirm {
    padding-top: 50px;
  }
}

.list-btn {
  max-width: 794px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45%, calc(50% - 32px)));
  justify-content: center;
  grid-gap: 64px;
}
@media screen and (max-width: 834px) {
  .list-btn {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}

.page-consultation .page-mv {
  padding: 188px 0 42px;
}
@media screen and (max-width: 834px) {
  .page-consultation .page-mv {
    padding: 60px 0;
  }
}

.page-mv__ttl {
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin-bottom: 38px;
  font-size: 3.6rem;
}
@media screen and (max-width: 1245px) {
  .page-mv__ttl {
    font-size: 3rem;
  }
}
@media screen and (max-width: 834px) {
  .page-mv__ttl {
    margin-bottom: 20px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 374px) {
  .page-mv__ttl {
    font-size: 1.8rem;
  }
}
.page-mv__ttl--lg {
  font-size: 5.2rem;
  margin-bottom: 18px;
}
@media screen and (max-width: 1245px) {
  .page-mv__ttl--lg {
    font-size: 4rem;
  }
}
@media screen and (max-width: 834px) {
  .page-mv__ttl--lg {
    font-size: 3rem;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 374px) {
  .page-mv__ttl--lg {
    font-size: 2rem;
  }
}
.page-mv__list {
  width: 100%;
  max-width: 805px;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .page-mv__list {
    margin: 0 -15px;
    width: calc(100% + 30px);
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.page-mv__item {
  width: 33.33%;
  padding: 0 17px;
}
@media screen and (max-width: 640px) {
  .page-mv__item {
    width: 50%;
    padding: 0 4px;
  }
}
.page-mv__item span {
  padding: 30px 10px;
  color: #fff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 175px;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
  font-size: 1.6rem;
  background: url("../img/consultation/bg-01.png") center no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 834px) {
  .page-mv__item span {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 640px) {
  .page-mv__item span {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    height: 150px !important;
  }
}
@media screen and (max-width: 374px) {
  .page-mv__item span {
    font-size: 1.2rem;
  }
}
.page-mv__item:nth-child(1) span {
  max-width: 214px;
}
.page-mv__item:nth-child(2) span {
  background: url("../img/consultation/bg-02.png") center no-repeat;
  height: 190px;
  background-size: 100% 100%;
}
.page-mv__item:nth-child(3) span {
  background: url("../img/consultation/bg-03.png") center no-repeat;
  height: 204px;
  background-size: 100% 100%;
}

.sec-works {
  padding: 80px 0 14px;
}
@media screen and (max-width: 834px) {
  .sec-works {
    padding: 50px 0 0;
  }
}

.works-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 834px) {
  .works-flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.works-flex__img {
  width: 53.35%;
  padding: 0 41px 0 125px;
}
@media screen and (max-width: 1245px) {
  .works-flex__img {
    padding: 0 30px 0 0;
  }
}
@media screen and (max-width: 834px) {
  .works-flex__img {
    padding: 0;
    width: 100%;
    margin-bottom: 15px;
  }
}
.works-flex__img img {
  width: 100%;
}
.works-flex__ct {
  width: 46.65%;
  font-weight: 500;
  padding-top: 54px;
}
@media screen and (max-width: 834px) {
  .works-flex__ct {
    padding-top: 0;
    width: 100%;
  }
}

.sec-point {
  padding: 50px 0 97px;
  background: url("../img/consultation/bg.png") top 0 left 51% no-repeat;
  background-size: 115%;
}
@media screen and (max-width: 834px) {
  .sec-point {
    padding: 50px 0;
    background: none;
  }
}
.sec-point .c-ttl__04 {
  margin-bottom: 91px;
}
@media screen and (max-width: 834px) {
  .sec-point .c-ttl__04 {
    margin-bottom: 25px;
  }
}

.point-list__item {
  margin-bottom: 49px;
}
@media screen and (max-width: 1245px) {
  .point-list__item {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 834px) {
  .point-list__item {
    margin-bottom: 35px;
  }
  .point-list__item:nth-child(1) {
    margin-bottom: 75px;
  }
  .point-list__item:nth-child(4) {
    margin-bottom: 95px;
  }
  .point-list__item:last-child {
    margin-bottom: 0;
  }
}
.point-list__item:nth-child(2n) {
  text-align: right;
}
@media screen and (min-width: 835px) {
  .point-list__item:nth-child(2) .point-list__ct {
    padding: 35px 74px 52px;
  }
}
.point-list__ct {
  max-width: 747px;
  width: 100%;
  text-align: left;
  border-radius: 20px;
  border: 2px solid #000;
  padding: 48px 60px 57px;
  background: #fff;
  display: inline-block;
  position: relative;
}
@media screen and (max-width: 834px) {
  .point-list__ct {
    max-width: 100%;
    padding: 30px 15px;
    border-width: 1px;
    border-radius: 10px;
  }
}
.point-list__number {
  color: #0079FF;
  font-weight: bold;
  margin-bottom: 13px;
  line-height: 1.2;
  font-size: 1.3rem;
}
@media screen and (max-width: 834px) {
  .point-list__number {
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
}
.point-list__ttl {
  color: #000;
  line-height: 1.3;
  font-weight: bold;
  margin-bottom: 32px;
  font-size: 2.4rem;
}
@media screen and (max-width: 834px) {
  .point-list__ttl {
    margin-bottom: 15px;
    font-size: 1.8rem;
  }
}
.point-list__img {
  max-width: 203px;
  width: 100%;
  position: absolute;
  top: -90px;
  right: 41px;
}
@media screen and (max-width: 1245px) {
  .point-list__img {
    max-width: 150px;
    top: -70px;
    right: 30px;
  }
}
@media screen and (max-width: 834px) {
  .point-list__img {
    max-width: 100px;
    right: 10px;
    top: -50px;
  }
}
.point-list__img img {
  width: 100%;
}
.point-list__img--02 {
  max-width: 198px;
  top: -179px;
  right: auto;
  left: 33px;
}
@media screen and (max-width: 1245px) {
  .point-list__img--02 {
    max-width: 150px;
    left: 30px;
    top: -120px;
  }
}
@media screen and (max-width: 834px) {
  .point-list__img--02 {
    max-width: 100px;
    left: 10px;
    top: -85px;
  }
}
.point-list__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .point-list__flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.point-list__flex img {
  width: 108px;
}
@media screen and (max-width: 834px) {
  .point-list__flex img {
    width: 10%;
    width: 110px;
    margin: 0 auto 15px;
  }
}
.point-list__flex p {
  padding-left: 40px;
}
@media screen and (max-width: 834px) {
  .point-list__flex p {
    padding-left: 0;
  }
}

.sec-consultation {
  background: #EDF3F5;
  padding: 70px 0 160px;
}
@media screen and (max-width: 834px) {
  .sec-consultation {
    padding: 50px 0 80px;
  }
}
.sec-consultation .c-txt__01 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 24px;
}
.sec-consultation .c-ttl__04 {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .sec-consultation .c-ttl__04 {
    margin-bottom: 25px;
  }
}

.consultation-list__item {
  padding: 70px 15px 0;
}
@media screen and (max-width: 640px) {
  .consultation-list__item {
    margin: 0 40px;
    padding: 70px 0 0;
  }
}
.consultation-list__ct {
  background: #fff;
  border-radius: 20px;
  padding: 36px;
  display: block;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 1245px) {
  .consultation-list__ct {
    padding: 30px 20px;
  }
}
.consultation-list__ct p {
  color: #000;
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .consultation-list__ct p {
    margin-bottom: 20px;
  }
}
.consultation-list__ct:before {
  content: "";
  position: absolute;
  background: white;
  width: calc(100% - 4px);
  height: calc(100% - 16px);
  border: 2px solid black;
  border-radius: 20px;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s ease;
  z-index: -1;
}
.consultation-list__ct:hover {
  opacity: 1;
  background: black;
  transition: 0.3s ease;
}
.consultation-list__ct:hover:before {
  opacity: 1;
  transition: 0.3s ease;
}
.consultation-list__img {
  margin-top: -100px;
  text-align: center;
  margin-bottom: 26px;
}
.consultation-list__img img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
}
.consultation-list__ttl {
  color: #000;
  margin-bottom: 35px;
  font-weight: bold;
  line-height: 1.5;
  padding: 0 10px;
  font-size: 2.4rem;
}
@media screen and (max-width: 1245px) {
  .consultation-list__ttl {
    padding: 0;
    font-size: 2rem;
  }
}
@media screen and (max-width: 834px) {
  .consultation-list__ttl {
    margin-bottom: 15px;
    padding: 0;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 640px) {
  .consultation-list .slick-prev, .consultation-list .slick-next {
    top: 57%;
  }
}

.sec-features-page {
  padding: 83px 0 140px;
}
@media screen and (max-width: 834px) {
  .sec-features-page {
    padding: 50px 0;
  }
}

.features-page-list__item {
  border: 2px solid black;
  border-radius: 20px;
  padding: 56px 74px 80px 71px;
  margin-bottom: 60px;
}
@media screen and (max-width: 1024px) {
  .features-page-list__item {
    padding: 30px;
  }
}
@media screen and (max-width: 834px) {
  .features-page-list__item {
    padding: 30px 15px;
    margin-bottom: 30px;
  }
}
.features-page-list__item:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 1025px) {
  .features-page-list__item--second {
    padding: 56px 74px 63px 59px;
  }
}
@media screen and (min-width: 641px) {
  .features-page-list__item--second .features-page-list__flex {
    align-items: center;
  }
}
@media screen and (min-width: 1025px) {
  .features-page-list__item--second .features-page-list__img {
    padding: 2px 0 0 7px;
  }
}
.features-page-list__ttl {
  font-size: 2.4rem;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 53px;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .features-page-list__ttl {
    font-size: 2.1rem;
  }
}
@media screen and (max-width: 640px) {
  .features-page-list__ttl {
    font-size: 1.7rem;
    margin-bottom: 15px;
  }
}
.features-page-list__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.features-page-list__ct {
  width: 56.67%;
  padding-right: 15px;
  max-width: 455px;
}
@media screen and (max-width: 640px) {
  .features-page-list__ct {
    width: 100%;
    padding: 0;
    max-width: 100%;
    margin-bottom: 15px;
  }
}
.features-page-list__ct p {
  margin-bottom: 16px;
}
.features-page-list__ct p:last-child {
  margin-bottom: 0;
}
.features-page-list__box {
  background: #edf3f5;
  padding: 23px 15px 32px;
  text-align: center;
  font-weight: 700;
  line-height: 1.75;
  margin: 26px 0 30px;
}
@media screen and (max-width: 834px) {
  .features-page-list__box {
    margin: 15px 0;
    padding: 15px 10px;
  }
}
.features-page-list__note {
  font-size: 1.3rem;
  line-height: 1.3846153846;
  font-weight: 300;
}
@media screen and (max-width: 834px) {
  .features-page-list__note {
    font-size: 1.2rem;
  }
}
.features-page-list__img {
  width: 43.33%;
  text-align: center;
  padding: 6px 0 0;
}
@media screen and (max-width: 640px) {
  .features-page-list__img {
    width: 100%;
    padding: 0;
  }
}

.sec-case {
  background: #EDF3F5;
  padding: 71px 0 106px;
}
@media screen and (max-width: 834px) {
  .sec-case {
    padding: 50px 0;
  }
}

.case-list__item {
  background: white;
  border-radius: 20px;
  padding: 46px 53px 46px 61px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
@media screen and (max-width: 834px) {
  .case-list__item {
    padding: 30px 15px;
    margin-bottom: 30px;
  }
}
.case-list__item:last-child {
  margin-bottom: 0;
}
.case-list__left {
  width: 48.4%;
  padding-right: 15px;
  max-width: 410px;
}
@media screen and (max-width: 1024px) {
  .case-list__left {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }
}
.case-list__ttl {
  margin-bottom: 10px;
}
.case-list__right {
  width: 51.6%;
  padding: 21px 0 0;
}
@media screen and (max-width: 1024px) {
  .case-list__right {
    width: 100%;
    padding: 0;
  }
}
.case-list__info {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.case-list__info:first-child > *, .case-list__info:last-child > * {
  border-top: none;
}
.case-list__info:last-child {
  border-top: 2px solid #000000;
}
.case-list__info dt {
  width: 63%;
}
@media screen and (max-width: 640px) {
  .case-list__info dt {
    width: 58%;
  }
}
.case-list__info dd {
  width: 35%;
}
@media screen and (max-width: 640px) {
  .case-list__info dd {
    width: 40%;
  }
}
.case-list__info > * {
  border-top: 1px solid #000000;
  padding: 10px 0;
}

.sec-role {
  padding: 31px 0 185px;
}
@media screen and (max-width: 834px) {
  .sec-role {
    padding: 0 0 50px;
  }
}

.role-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 80px;
}
@media screen and (max-width: 834px) {
  .role-flex {
    margin-bottom: 50px;
  }
}
.role-flex__item {
  width: 47.2%;
  max-width: 580px;
}
@media screen and (max-width: 640px) {
  .role-flex__item {
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px;
    position: relative;
  }
}
.role-flex__item:after {
  position: absolute;
  content: "";
  border-left: 21px solid #000;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  top: 51.5%;
  left: 50%;
}
@media screen and (max-width: 640px) {
  .role-flex__item:after {
    border-top: 14px solid #000;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: calc(100% + 24px);
    left: calc(50% - 8px);
  }
}
.role-flex__item:last-child:after {
  display: none;
}
@media screen and (max-width: 640px) {
  .role-flex__item:last-child {
    margin-bottom: 0;
  }
}
.role-flex__ttl {
  margin-bottom: 7px;
  color: #0079FF;
}

.role-position {
  max-width: 1046px;
  margin: 0 auto;
}
.role-position__item {
  border: 2px solid black;
  border-radius: 20px;
  padding: 56px 76px 67px;
  margin-bottom: 80px;
}
@media screen and (max-width: 1024px) {
  .role-position__item {
    padding: 30px;
  }
}
@media screen and (max-width: 834px) {
  .role-position__item {
    margin-bottom: 30px;
    padding: 30px 15px;
    border-radius: 10px;
  }
}
.role-position__item:last-child {
  margin-bottom: 0;
}