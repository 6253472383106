.page-mv{
	text-align: center;
    padding: 218px 0 127px;
    background: linear-gradient(125deg, #DBAAB6, #3979F6);
	color: white;
	position: relative;
	@include mq{
		padding: 60px 0;
	}
	>*{
		position: relative;
		z-index: 9;
	}
	@include p-after(100%, 100%){
		background: url(/img/template/bg-01.png) center no-repeat;
		background-size: 100% 100%;
		inset: 0 auto auto 0;
		z-index: 1;
		@include mq(xl){
			background-size: cover;
		}
		@include mq{
			display: none;
		}
	}
}
.link-scroll{
	@include fts(24, 36);
	font-weight: 700;
	position: fixed;
	color: white;
	right: 0;
	width: 437px;
	height: 140px;
	border-width: 2px;
	border-radius: 20px 0 0 0;
	background: linear-gradient(90deg, #DBAAB6, #3979F6);
	bottom: 0;
	@include flex(center, center);
	z-index: 10;
	@include mq{
		width: 200px;
		height: 70px;
		@include font-size(16);
	}
	&:hover{
		color: #3979F6;
		border-style: solid;
		border-color: currentColor;
		background: white;
		opacity: 1;
		&::after{
			right: 60px;
		}
	}
	&:after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-top: 9px solid transparent;
		border-left: 16px solid currentColor;
		border-bottom: 9px solid transparent;
		top: 50%;
		transform: translateY(-50%);
		right: 96px;
		transition: 0.3s ease;
		
		@include mq {
			border-top: 6px solid transparent;
			border-left: 8px solid currentColor;
			border-bottom: 6px solid transparent;
			right: 15px;
		}
	}
}
.sec-product{
	padding-top: 85px;
	padding-bottom: 175px;
	@include mq{
		padding: 50px 0;
	}
}
.product-box{
	margin-bottom: 218px;
	@include mq{
		margin-bottom: 50px;
	}
	&__top{
		border-bottom: 2px solid black;
		margin-bottom: 46px;
	}
	&__tab{
		&-ct{
			display: none;
			&.active{
				display: block;
			}
		}
		.inner{
			max-width: 1406px;
		}
	}
}
.product-link{
	@include flex(center, 0, wrap);
	margin: 0 -53px;
	@include fts(24, 36);
	@include mq{
		margin: 0 -10px;
		@include font-size(16);
	}

	li{
		padding: 0 53px;
		margin-bottom: -2px;
		max-width: 445px;
		width: 33.33%;
		@include mq{
			padding: 0 10px;
			// width: 100%;
			// margin-bottom: 15px;
			// &:last-child{
			// 	margin-bottom: -2px;
			// }
		}
		&.active a, a:hover, &.active span, span:hover{
			opacity: 1;
			background: black;
			color: white;
		}
		span, a{
			text-align: center;
			padding: 19px 5px 16px;
			color: black;
			border: 2px solid black;
			border-radius: 20px 20px 0 0;
			@include flex(center, center);
			cursor: pointer;
			height: 100%;
			transition:  all .35s;
			font-weight: 700;
			@include mq{
				padding: 8px 5px;
			}
		}
	}
}
.product-column{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 69px;
	line-height: 1.8;
	@include mq(xl){
		grid-gap: 30px;
	}
	@include mq{
		grid-template-columns: repeat(2, 1fr);
	}
	@include mq(sm){
		grid-gap: 5px;
		//grid-template-columns: 1fr;
	}
	&__item{
		display: block;
		color: black;
		border: 2px solid black;
		border-radius: 20px;
		overflow: hidden;
	}
	&__img{
		position: relative;
		overflow: hidden;
		&::before{
			content: "";
			display: block;
			padding-top: 54.56%;
		}
		&-thumb{
			position: absolute;
			inset: 0 auto auto 0;
			height: 100%;
			width: 100%;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	&__ct{
		padding: 20px 29px 11px;
		@include mq(sm) {
			padding: 10px;
		}
	}
	&__category{
		@include font-size(16);
		font-weight: 700;
		background: black;
		color: white;
		display: inline-block;
		padding: 6px 15px;
		position: absolute;
		inset: auto auto 0 26px;
		@include mq{
			@include font-size(13);
		}
		@include mq(sm) {
			left: 10px;
		}
	}
	&__refer{
		background: #EDF3F5;
		@include fts(16, 28);
		font-weight: 500;
		padding: 10px 5px;
		margin-bottom: 8px;
		text-align: center;
		@include flex(center, center);
		@include mq{
			@include font-size(13);
		}
	}
	&__title{
		border-bottom: 2px solid black;
		display: block;
		padding-bottom: 6px;
   		margin-bottom: 14px;
		min-height: 79px;
		@include mq{
			min-height: 56px
		}
	}
	&__price{
		@include fts(16, 24);
		text-align: right;
		@include mq{
			@include font-size(13);
		}
		span{
			@include font-size(28);
			@include mq(xl){
				@include font-size(24);
			}
			@include mq{
				@include font-size(20);
			}
		}
	}
}
.txt-under {
    text-decoration: underline;
}
.part-info{
	margin-bottom: 157px;
	@include mq{
		margin-bottom: 50px;
	}
	&__img{
		margin-bottom: 40px;
	}
	&__ct{
		margin-bottom: 44px;
		p{
			a{
				color: #0079FF;
			}
		}
	}
}
.part-block{
	@include flex(between, 0, wrap);
	margin-bottom: 122px;
	@include mq{
		margin-bottom: 50px;
	}
	&__img{
		width: calc(50% - 24px);
		@include mq{
			width: 100%;
			margin-bottom: 20px;
		}
	}
	&__ct{
		width: calc(50% - 33px);
		@include flex(0,0,0,0,1);
		@include mq{
			width: 100%;
		}
		p{
			a{
				color: #0079FF;
			}
		}
		&-boxlink{
			margin-top: auto;
			text-align: right;
			@include mq{
				margin-top: 15px;
			}
		}
	}
	&--02{	
		margin-bottom: 240px;
		@include mq{
			flex-direction: column-reverse;
			margin-bottom: 50px;
		}
		.c-block{

			&__img{
				
				@include mq(md-min){
					width: calc(50% - 35px);
				}
			}
			&__ct{
				@include mq(md-min){
					width: calc(50% - 21px);
				}
			}
		}
	}
}
.part-desc{
	@include fts(24, 48);
	font-weight: 500;
	margin-bottom: 166px;
	@include mq{
		margin-bottom: 50px;
		@include fts(16, 30);
	}
	&__box{
		background: #EDF3F5;
		border-radius: 20px;
		padding: 37px 62px;
		@include mq{
			padding: 15px 30px;
		}
	}
	&__title{
		@include fts(24, 35);
		font-weight: 700;
		text-align: center;
		margin-bottom: 33px;
	}
	&--02{
		@include fts(20, 40);
		font-weight: 500;
		max-width: 1046px;
		margin: 0 auto 194px;
		@include mq{
			@include fts(13,22);
			margin-bottom: 50px;
		}
		.part-desc__box{
			padding: 37px 48px;
			background: transparent;
			border: 2px solid black;
			@include mq{
				padding: 15px 30px;
			}
		}
	}
}
.c-list{
	counter-reset: number;
	&__item{
		position: relative;
		padding-left: 31px;
		&::before{
			counter-increment: number;
			content: counter(number) ". ";
			position: absolute;
			left: 0;
		}
	}
}
.part-work{
	max-width: 564px;
	margin: 0 auto 194px;
	@include mq{
		margin-bottom: 50px;
		max-width: 100%
	}
	&__item{
		@include flex(0, 0, wrap);
		
		&:not(:last-child){
			padding-bottom: 10px;
			margin-bottom: 13px;
			border-bottom: 1px solid;
		}
	}
	&__title{
		width: 140px;
		font-weight: 400;
		@include fts(16, 36);
		@include mq{
			line-height: inherit;
			width: 110px;
			@include font-size(13);
		}
	}
	&__ct{
		width: calc(100% - 140px);
		font-weight: 500;
		padding-left: 3px;
		@include mq{
			width: calc(100% - 110px);
		}
	}
}
.part-workinfo{
	@include flex(between, 0, wrap);
	&__left{
		width: 50%;
		max-width: 559px;
		padding-right: 10px;
		@include mq{
			width: 100%;
			max-width: 100%;
			margin-bottom: 15px;
		}
	}
	&__right{
		width: 50%;
		max-width: 566px;
		padding-left: 10px;
		@include mq{
			width: 100%;
			max-width: 100%;
			padding-left: 0;
		}
	}
}
.sec-informition{
	padding-bottom: 131px;
	@include mq{
		padding-bottom: 60px;
	}
	&__title{
		@include fts(48, 93.6);
		font-weight: 700;
		text-align: center;
		margin-bottom: 7px;
		@include mq{
			@include font-size(20);
			margin-bottom: 30px;
		}
	}
}
.part-informition{
	border: 2px solid black;
	border-radius: 20px;
	padding: 40px 92px 48px;
	margin-bottom: 158px;
	@include mq{
		margin-bottom: 50px;
		padding: 15px 20px;
	}
	&__item{
		
		&:not(:last-child){
			border-bottom: 1px solid black;
			padding-bottom: 20px;
			margin-bottom: 11px;
		}
		a{
			@include flex(0, 0, wrap);
			color: black;
			cursor: pointer;
		}
	}
	&__date{
		width: 149px;
		@include mq{
			width: 100%;
			margin-bottom: 10px;
		}
	}
	&__ct{
		width: calc(100% - 149px);
		padding-left: 5px;
		font-weight: 400;
		@include mq{
			width: 100%;
			padding-left: 0;
		}
	}
}
.informition-contact{
	max-width: 892px;
	margin: 0 auto 50px;
	line-height: 1.75;
	@include mq{
		margin-bottom: 30px;
	}
	.required{
		@include fts(12, 12);
		font-weight: 500;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: white;
		background: #3979F6;
		text-align: center;
		width: 35px;
		height: 19px;
		// transform: translateY(-4px);
		margin-left: 7px;
	}
	&__item{
		margin-bottom: 51px;
		@include mq{
			margin-bottom: 25px;
		}
	}
	&__txt{
		@include fts(24, 36);
		@include mq{
			@include font-size(16);
		}
	}
	&__title {
		display: block;
		margin-bottom: 12px;
	}
	&__note{
		@include font-size(14);
    	margin-top: 8px;
		font-weight: 400;
	}
	&__confirm{
		text-align: center;
		padding-top: 180px;
		@include mq{
			padding-top: 50px;
		}
	}
}
.list-btn{
	max-width: 794px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fit,minmax(45%,calc(50% - 32px)));
	justify-content: center;
	grid-gap: 64px;
	@include mq{
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}
}
