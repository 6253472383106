.sec-mv {
	background: #EDF3F5;
	padding-top: 207px;
	overflow: hidden;
	@include mq {
		padding-top: 52px;
	}

	.inner {
		position: relative;
	}

	.c-btn__scroll {
		left: 42px;
	}
}

.mv-wrap {
	position: relative;

	&__ct {
		position: absolute;
		width: 560px;
		top: 44.6%;
		left: 0;
		transform: translateY(-50%);
		@include mq {
			position: relative;
			width: 100%;
			top: 0;
			transform: unset;
			margin: -21px 0 23px;
		}

		&-ttl {
			font-weight: bold;
			@include fts(80, 108);
			margin-bottom: 40px;
			@include mq(xl) {
				@include font-size(55);
			}
			@include mq(ipd) {
				@include fts(40, 56);
				margin-bottom: 20px;
			}
		}

		&-txt {
			font-weight: bold;
			@include fts(24, 42);
			@include mq(xl) {
				@include font-size(20);
			}
			@include mq(ipd) {
				@include fts(16, 26);
			}
		}
	}

	&__img {
		position: relative;
		width: calc(100% - 502px);
		margin-left: auto;
		@include mq(xl) {
			width: calc(100% - 315px);
		}
		@include mq(ipd) {
			width: calc(100% - 210px);
		}
		@include mq {
			position: relative;
			width: 100%;
			left: -9px;
		}

		span {
			position: relative;
			width: auto;
			display: block;
			margin-right: -177px;
			@media screen and (max-width: 1720px) {
				margin-right: calc((1366px - 100vw) / 2);
			}
			@media screen and (max-width: 1720px) {
				margin-right: calc((1366px - 100vw) / 2);
			}
			@media screen and (max-width: 1406px) {
				margin-right: -20px;
			}

			img {
				width: 100%;
			}
		}

		&-txt {
			position: absolute;
			@include flex(center, center);
			text-align: center;
			color: #B1C3CB;
			font-weight: 500;
			@include fts(18, 32);
			@include mq(sm) {
				@include font-size(12);
			}

			&:first-of-type {
				//background: url("../img/index/canvas1.png") center no-repeat;
				//background-size: contain;
				width: 236px;
				height: 227px;
				padding: 0 14px 21px 0;
				top: 6%;
				left: 11.8%;
				@include mq(mac) {
					top: -3%;
					left: 6.8%;
				}
				@include mq(ipd) {
					top: -9%;
				}
				@include mq(sm) {
					width: 121px;
					height: 116px;
					padding: 40px 0 0;
					top: auto;
					bottom: 29px;
					left: -25px;
				}
				@include mq(xs) {
					bottom: 4px;
					left: -14px;
				}
			}

			&:nth-of-type(2) {
				//background: url("../img/index/canvas2.png") center no-repeat;
				//background-size: contain;
				width: 267px;
				height: 233px;
				padding: 0 15px 6px 0;
				top: -1.8%;
				right: 3%;
				@include mq(lp) {
					top: -5.8%;
				}
				@include mq(ipd) {
					top: -9.8%;
				}
				@include mq(sm) {
					width: 137px;
					height: 119px;
					top: -5px;
					right: -54px;
					padding: 0 22px 8px 0;
				}
				@include mq(xs) {
					top: -19px;
				}
			}

			&:nth-of-type(3) {
				//background: url("../img/index/canvas3.png") center no-repeat;
				//background-size: contain;
				width: 237px;
				height: 173px;
				bottom: 19.3%;
				left: 23.5%;
				@include mq(lp) {
					bottom: 13.3%;
				}
				@include mq(sm) {
					width: 121px;
					height: 89px;
					bottom: -132px;
					left: auto;
					right: -62px;
					padding-right: 20px;
				}
			}

			canvas {
				position: absolute;
				@include v-h-align;
				pointer-events: none;
			}

			p {
				position: relative;
				z-index: 1;
			}
		}
	}
}

.mv-btn {
	position: fixed;
	@include flex(center, center);
	width: 100%;
	max-width: 437px;
	height: 140px;
	background: linear-gradient(90deg, #DBAAB6, #3979F6);
	color: white;
	border-top-left-radius: 20px;
	font-weight: bold;
	@include font-size(24);
	right: 0;
	bottom: 0;
	z-index: 10;
	@include mq {
		margin-top: 0;
		width: 100%;
		max-width: 187px;
		height: 53px;
		@include font-size(13);
		padding: 6px 13px 0 0;
	}

	&:after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-top: 16px solid #fff;
		top: 62px;
		right: 85px;
		@include mq {
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 10px solid #fff;
			top: 25px;
			right: 21px;
		}
	}
}

.mv-news {
	position: relative;
	@include flex(0, center);
	width: calc(100% - 70px);
	max-width: 981px;
	background: black;
	color: white;
	border-top-left-radius: 20px;
	font-weight: 500;
	@include font-size(16);
	margin: -64px 0 0 auto;
	padding: 28px 30px 20px;
	@include mq {
		width: calc(100% + 40px);
		border-radius: 0;
		@include font-size(13);
		margin: 0 -20px;
		padding: 14px 20px 17px;
	}

	&:after {
		content: "";
		position: absolute;
		background: black;
		width: calc((100vw - 1366px) / 2);
		height: 100%;
		top: 0;
		left: 100%;
		@media screen and (max-width: 1406px) {
			width: 20px;
		}
		@include mq {
			display: none;
		}
	}

	&__tag {
		@include flex(center, center);
		width: 117px;
		height: 44px;
		border-radius: 40px;
		border: 1px solid white;
		@include mq {
			width: 74px;
			height: 26px;
		}
	}

	&__txt {
		width: calc(100% - 295px);
		padding: 0 27px 4px;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		max-height: 27px;
		@include mq {
			width: calc(100% - 151px);
			max-height: 22px;
			padding: 2px 14px 0;
		}
	}

	&__btn {
		position: relative;
		width: 99px;
		color: white;
		padding-bottom: 4px;
		@include mq {
			width: 77px;
			padding-bottom: 2px;
		}

		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-top: 7px solid transparent;
			border-left: 12px solid #fff;
			border-bottom: 7px solid transparent;
			top: 5px;
			right: 0;
			transition: 0.3s ease;
			@include mq {
				border-top: 5px solid transparent;
				border-left: 8px solid #fff;
				border-bottom: 5px solid transparent;
				top: 4px;
				right: 2px;
			}
		}

		&:hover {
			opacity: 1;
			text-decoration: underline;
			&:after {
				right: -5px;
				transition: 0.3s ease;
			}
		}
	}
}

.sec-intro {
	padding: 136px 0 218px;
	@include mq {
		padding: 47px 0 77px;
	}
}

.intro-wrap {
	@include flex(between, 0, wrap);
	margin-bottom: 59px;
	@include mq {
		margin-bottom: 26px;
	}

	.c-ttl__01 {
		width: 500px;
		text-align: left;
		@include mq(ipd) {
			width: 375px;
		}
		@include mq {
			text-align: center;
			width: 100%;
		}
	}

	&__txt {
		width: calc(100% - 575px);
		font-weight: 500;
		@include fts(20, 41.8);
		padding-top: 11px;
		@include mq(ipd) {
			width: calc(100% - 375px);
		}
	}
}

.intro-block {
	position: relative;
	@include flex(between, start, wrap);
	border-radius: 20px 0 0 20px;
	border: solid black;
	border-width: 2px 0 2px 2px;
	padding: 55px 0 39px;
	@include mq {
		border-width: 2px;
		border-radius: 20px;
		padding: 38px 17px 7px;
	}

	&:after {
		content: "";
		position: absolute;
		width: calc((100vw - 1366px) / 2);
		height: 100%;
		border: solid black;
		border-width: 2px 0;
		top: -2px;
		left: 100%;
		@media screen and (max-width: 1406px) {
			width: 20px;
		}
		@include mq {
			display: none;
		}
	}

	&--also {
		&:before {
			content: "";
			position: absolute;
			background: url("/img/index/also-shape.png") center no-repeat;
			background-size: contain;
			width: 240px;
			height: 263px;
			top: -213px;
			left: 16.2%;
			transform: translateX(-50%);
			@include mq(xl) {
				width: 131px;
				height: 143px;
				top: -117px;
				left: 12.2%;
			}
			@include mq(sm) {
				left: 23.1%;
			}
		}

		.intro-block__logo {
			padding: 48px 0 0 67px;
			@include mq {
				padding: 11px 0 22px;
			}
		}
	}

	&__logo {
		text-align: center;
		width: 40%;
		padding-top: 26px;
		@include mq {
			width: 100%;
			@include fts(16, 28);
			padding-top: 0;
			margin-bottom: 16px;
			p {
				text-align: left;
				margin-top: 28px;
			}
		}
	}

	&__list {
		width: 58.1%;
		@include mq {
			width: 100%;
		}

		&-item {
			@include flex();
			max-width: 632px;
			border-bottom: 1px solid black;
			padding: 9px 0;
			@include mq {
				max-width: 100%;
				padding: 8px 0 9px;
			}

			&:last-of-type {
				border-bottom: 0;
			}
		}

		&-ttl {
			width: 142px;
			@include font-size(16);
			padding-top: 5px;
			@include mq {
				width: 93px;
				@include font-size(13);
				padding-top: 2px;
			}
		}

		&-txt {
			width: calc(100% - 142px);
			font-weight: 500;
			@include mq {
				width: calc(100% - 93px);
			}
		}
	}

	&__ct {
		width: 56.4%;
		padding-top: 42px;
		@include mq {
			width: 100%;
			font-weight: 500;
			padding-top: 0;
		}

		&-ttl {
			font-weight: bold;
			@include font-size(52);
			margin-bottom: 33px;
			line-height: 1;
			@include mq {
				margin-bottom: 21px;
				span {
					display: none;
				}
			}
			@include mq(sm) {
				margin-right: -10px;
			}

			small {
				display: block;
				font-family: $font-Yugothic;
				@include font-size(28);
				margin-bottom: 9px;
				@include mq {
					@include font-size(20);
				}
			}
		}

		&-btn {
			position: relative;
			@include flex(center, center);
			text-align: center;
			width: 100%;
			max-width: 382px;
			height: 105px;
			background: black;
			color: white;
			border: 3px solid black;
			border-radius: 50px;
			font-weight: 600;
			@include fts(22, 28);
			margin: 28px 0 11px auto;
			padding: 5px 30px;
			@include mq {
				max-width: 222px;
				height: 61px;
				@include fts(13, 20);
				margin: 29px auto;
			}

			&:before {
				content: "";
				position: absolute;
				background: url("/img/index/icon-link-white.svg") center no-repeat;
				background-size: contain;
				width: 28px;
				height: 28px;
				top: 34px;
				right: 44px;
				transition: 0.3s ease;
				@include mq {
					width: 15px;
					height: 15px;
					top: 20px;
					right: 24px;
				}
			}

			&:after {
				content: "";
				position: absolute;
				background: url("/img/index/arrow-white.svg") center no-repeat;
				background-size: contain;
				width: 21px;
				height: 21px;
				top: 28px;
				right: 39px;
				transition: 0.3s ease;
				@include mq {
					width: 12px;
					height: 12px;
					top: 17px;
					right: 21px;
				}
			}
			
			&:hover {
				opacity: 1;
				@include mq(md-min){
					background: white;
					color: black;
	
					&:before {
						background: url("/img/index/icon-link-black.svg") center no-repeat;
						background-size: contain;
						transition: 0.3s ease;
					}
	
					&:after {
						background: url("/img/index/arrow-black.svg") center no-repeat;
						background-size: contain;
						transition: 0.3s ease;
						top: 24px;
						right: 35px;
						@include mq {
							top: 14px;
							right: 18px;
						}
					}
				}
			}
			
		}
	}
}

.sec-trouble {
	background: #EDF3F5;
	padding: 74px 0 161px;
	@include mq {
		padding: 36px 0 111px;
	}
}

.trouble-list {
	margin: 0 -14px;
	@include mq(lp) {
		margin: 0 50px;
	}
	@include mq(sm) {
		margin: 0;
	}

	.slick-track {
		display: flex !important;
	}

	.slick-slide {
		height: inherit !important;
	}

	.slick-prev, .slick-next {
		top: 49%;
		width: 50px;
		height: 50px;
		background: white;
		border-radius: 50%;
		transition: 0.3s ease;
		z-index: 1;
		@include mq(sm) {
			top: 45%;
			width: 30px;
			height: 30px;
		}

		&:before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-top: 10px solid transparent;
			border-left: 17px solid #edf3f5;
			border-bottom: 10px solid transparent;
			top: 16px;
			@include mq(sm) {
				border-top: 6px solid transparent;
				border-left: 10px solid #edf3f5;
				border-bottom: 6px solid transparent;
				top: 9px;
			}
		}

		&:hover {
			opacity: 0.7;
			transition: 0.3s ease;
		}
	}

	.slick-next {
		right: -78px;
		@include mq(xl) {
			right: -50px;
		}
		@include mq(sm) {
			right: 0;
		}
		@include mq(xs) {
			right: -15px;
		}

		&:before {
			right: 15px;
			@include mq(sm) {
				right: 8px;
			}
		}
	}

	.slick-prev {
		left: -78px;
		@include mq(xl) {
			left: -50px;
		}
		@include mq(sm) {
			left: 0;
		}
		@include mq(xs) {
			left: -15px;
		}

		&:before {
			transform: scaleX(-1);
			left: 15px;
			@include mq(sm) {
				left: 8px;
			}
		}
	}

	.slick-dots {
		bottom: -52px;
		left: -1px;

		li {
			margin: 0 8px;

			button {
				&:before {
					width: 11px;
					height: 11px;
					background: #ACC4CC;
				}
			}

			&.slick-active {
				button {
					&:before {
						border: 0;
						background: #798289;
					}
				}
			}
		}
	}

	&__item {
		position: relative;
		background: white;
		border-radius: 20px;
		font-weight: 500;
		@include fts(16, 28.8);
		padding: 45px 37px;
		margin: 0 14px;
		cursor: pointer;
		transition: 0.3s ease;
		@include mq(lp) {
			padding: 30px 22px 18px;
		}
		@include mq {
			@include fts(13, 22);
		}
		@include mq(sm) {
			margin: 0 40px;
		}
		@include mq(xs) {
			margin: 0 20px;
		}

		a {
			display: block;
			color: black;
			> * {
				position: relative;
			}
			&:hover {
				opacity: 1;
			}
		}

		&:before {
			content: "";
			position: absolute;
			background: white;
			width: calc(100% - 4px);
			height: calc(100% - 16px);
			border: 2px solid black;
			border-radius: 20px;
			top: 0;
			left: 0;
			opacity: 0;
			transition: 0.3s ease;
		}

		&:hover {
			background: black;
			transition: 0.3s ease;

			&:before {
				opacity: 1;
				transition: 0.3s ease;
			}
		}
	}

	&__img {
		@include flex(center, center);
		margin-bottom: 38px;
		@include mq(sm) {
			height: 170px !important;
			margin-bottom: 13px;
		}
	}

	&__ttl {
		display: block;
		text-align: center;
		font-weight: bold;
		@include fts(24, 36);
		margin-bottom: 23px;
		@include mq(xl) {
			@include font-size(18);
		}
		@include mq {
			@include fts(16, 24);
			margin-bottom: 12px;
		}
		@include mq(xs) {
			@include font-size(15);
		}
	}
}

.sec-features {
	padding: 71px 0 149px;
	@include mq {
		padding: 40px 0 60px;
		.c-txt__01 {
			font-weight: 500;
		}
		.c-btn__01 {
			max-width: 195px;
			height: 54px;
		}
	}
}

.features-wrap {
	margin-bottom: 42px;
	@include mq(sm-min) {
		border-radius: 20px;
		border: 2px solid black;
		padding: 32px 15px 29px;
	}
	@include mq(sm) {
		overflow-x: scroll;
		margin-right: -20px;
		padding-right: 20px;
		margin-bottom: 25px;
	}
}

.features-list {
	@include flex(between, 0, wrap);
	max-width: 1050px;
	margin: 0 auto;
	@include mq(sm) {
		width: 715px;
		border-radius: 20px;
		border: 2px solid black;
		padding: 21px 48px 25px;
	}

	&__item {
		position: relative;
		text-align: center;
		width: 30%;
		font-weight: 500;
		@include fts(16, 28.8);
		@include mq {
			width: 33%;
			@include fts(13, 22);
		}

		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-top: 11px solid transparent;
			border-left: 20px solid black;
			border-bottom: 11px solid transparent;
			top: 49.5%;
			right: -32px;
			transform: translateY(-50%);
			@include mq {
				right: -20px;
			}
			@include mq(sm) {
				border-top: 7px solid transparent;
				border-left: 13px solid #000;
				border-bottom: 7px solid transparent;
				top: 50.8%;
				right: 0;
			}
		}

		&:last-of-type {
			&:after {
				display: none;
			}
		}

		.c-ttl__02 {
			@include mq {
				margin-bottom: 3px;
			}
		}
	}

	&__img {
		@include flex(center, center);
		margin-bottom: 7px;
		@include mq {
			padding: 0 20px;
			margin-bottom: 9px;
		}
	}
}

.features-txt {
	text-align: center;
	@include mq {
		text-align: left;
		padding: 0 20px;
	}
}

.sec-support {
	background: #EDF3F5;
	padding: 120px 0 190px;
	@include mq {
		padding: 32px 0 80px;
	}
}

.support-wrap {
	@include flex(between, 0, wrap);

	&__item {
		width: 48.4%;
		background: white;
		border-radius: 20px;
		padding: 39px 70px 45px;
		@include mq(ipd) {
			padding: 24px 20px 25px;
		}
		@include mq(sm) {
			width: 100%;
			margin-bottom: 33px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.c-ttl__02 {
			margin-bottom: 28px;
			@include mq {
				margin-bottom: 21px;
			}
		}
	}

	&__box {
		background: #EDF3F5;
		padding: 34px 40px 32px;
		margin-bottom: 21px;
		@include mq(ipd) {
			padding: 19px 20px 15px;
			margin-bottom: 14px;
		}
		@include mq {
			+ p {
				font-weight: 500;
			}
		}
	}

	&__note {
		font-weight: 300;
		@include fts(16, 28.8);
		margin-top: 15px;
		@include mq {
			@include fts(13, 22);
		}
		@include mq(xs) {
			@include font-size(11);
		}
	}

	&__img {
		text-align: center;
		margin: 102px 0 110px;
		@include mq(sm) {
			margin: -10px 0 27px;
			img {
				width: 111px;
			}
		}
	}
}

.sec-also {
	padding: 129px 0 164px;
	overflow: hidden;
	@include mq(sm) {
		padding: 48px 0 63px;
	}
	@include mq(sm) {
		.c-ttl__01 {
			transform: translateX(-50%);
			left: calc(50% + 55px);
		}
	}
}
