.sec-features-page {
	padding: 83px 0 140px;
	@include mq {
		padding: 50px 0;
	}
}
.features-page-list {
	&__item {
		border: 2px solid black;
		border-radius: 20px;
		padding: 56px 74px 80px 71px;
		margin-bottom: 60px;
		@include mq(ipd) {
			padding: 30px;
		}
		@include mq {
			padding: 30px 15px;
			margin-bottom: 30px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&--second {
			@include mq(ipd-min) {
				padding: 56px 74px 63px 59px;
			}
			.features-page-list {
				&__flex {
					@include mq(sm-min) {
						align-items: center;
					}
				}
				&__img {
					@include mq(ipd-min) {
						padding: 2px 0 0 7px;
					}
				}
			}
		}
	}
	&__ttl {
		@include fts(24,36);
		font-weight: bold;
		margin-bottom: 53px;
		text-align: center;
		@include mq(ipd) {
			@include font-size(21);
		}
		@include mq(sm) {
			@include font-size(17);
			margin-bottom: 15px;
		}
	}
	&__flex {
		@include flex(between, start, wrap);
	}
	&__ct {
		width: 56.67%;
		padding-right: 15px;
		max-width: 455px;
		@include mq(sm) {
			width: 100%;
			padding: 0;
			max-width: 100%;
			margin-bottom: 15px;
		}
		p {
			margin-bottom: 16px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__box {
		background: #edf3f5;
		padding: 23px 15px 32px;
		text-align: center;
		font-weight: 700;
		line-height: 1.75;
		margin: 26px 0 30px;
		@include mq {
			margin: 15px 0;
			padding: 15px 10px;
		}
	}
	&__note {
		@include fts(13,18);
		font-weight: 300;
		@include mq {
			@include font-size(12);
		}
	}
	&__img {
		width: 43.33%;
		text-align: center;
		padding: 6px 0 0;
		@include mq(sm) {
			width: 100%;
			padding: 0;
		}
	}
}

.sec-case {
	background: #EDF3F5;
	padding: 71px 0 106px;
	@include mq(){
		padding: 50px 0;
	}
}
.case-list {
	&__item {
		background: white;
		border-radius: 20px;
		padding: 46px 53px 46px 61px;
		@include flex(between, start, wrap);
		margin-bottom: 80px;
		@include mq {
			padding: 30px 15px;
			margin-bottom: 30px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__left {
		width: 48.4%;
		padding-right: 15px;
		max-width: 410px;
		@include mq(ipd) {
			width: 100%;
			max-width: 100%;
			padding: 0;
			margin-bottom: 20px;
		}
	}
	&__ttl {
		margin-bottom: 10px;
	}
	&__right {
		width: 51.6%;
		padding: 21px 0 0;
		@include mq(ipd) {
			width: 100%;
			padding: 0;
		}
	}
	&__info {
		@include flex(between, 0, wrap);
		&:first-child, &:last-child {
			> * {
				border-top: none;
			}
		}
		&:last-child {
			border-top: 2px solid #000000;
		}
		dt {
			width: 63%;
			@include mq(sm) {
				width: 58%;
			}
		}
		dd {
			width: 35%;
			@include mq(sm) {
				width: 40%;
			}
		}
		> * {
			border-top: 1px solid #000000;
			padding: 10px 0;
		}
	}
}