/* ====================================================
Control
==================================================== */
.c-fc { //font-color
	&--white {
		color: white !important;
	}

	&--black {
		color: black !important;
	}
}


.c-ft { //font-family
	//&--en {
	//	font-family: $font-en;
	//}

	&--jp {
		font-family: $font-jp;
	}
}


.c-fw { //font-weight
	&--lt {
		font-weight: 300 !important
	}

	&--rg {
		font-weight: 400 !important
	}

	&--md {
		font-weight: 500 !important
	}

	&--sbd {
		font-weight: 600 !important
	}

	&--bd {
		font-weight: 700 !important
	}

	&--bk {
		font-weight: 900 !important
	}
}


.c-tx--v {
	//Vertical writing
	//display: inline-block;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;

	&-pc {
		@include mq(md-min) {
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
		}
	}
}


.c-al {
	&--c {
		text-align: center !important;
	}

	&--r {
		text-align: right !important
	}

	&--l {
		text-align: left !important;
	}
}


// Image position control
.c-pull {
	&--left {
		margin-left: calc(((100vw - 100%) / 2) * -1)
	}

	&--right {
		margin-right: calc(((100vw - 100%) / 2) * -1)
	}
}


/* ====================================================
Component
==================================================== */
// Common Text style
.c-txt {
	&__01 {
		@include fts(20, 36);
		font-weight: 700;
		@include mq(xl){
			@include font-size(18);
		}
		@include mq {
			@include fts(13, 22);
		}
	}
	&__02{
		@include fts(16, 32);
		font-weight: 500;
		@include mq {
			@include fts(13, 22);
		}
	}
	&__03{
		@include fts(16, 28.8);
		font-weight: 500;
		@include mq {
			@include fts(13, 22);
		}
	}
}


//ttl
.c-ttl {
	&__01 {
		@include fts(52, 93.6);
		font-weight: 700;
		text-align: center; 
		position: relative;
		@include mq(ipd) {
			@include font-size(40);
		}
		@include mq{
			@include fts(24, 36);
		}
		&--line{
			
		}
	}
	&__02 {
		text-align: center;
		display: block;
		font-weight: bold;
		@include fts(36, 64.8);
		@include mq {
			@include fts(20, 36);
		}
	}
	&__03{
		@include fts(32, 46);
		font-weight: 700;
		text-align: center;
		margin-bottom: 79px;
		@include mq{
			@include font-size(18);
			margin-bottom: 30px;
		}
		span{
			display: inline-block;
			padding-bottom: 10px;
			border-bottom: 3px solid #3979F6;
		}
	}
	&__04{
		padding-bottom: 35px;
		margin-bottom: 80px;
		@include fts(48, 93.6);
		font-weight: 700;
		position: relative;
		text-align: center;
		@include mq{
			margin-bottom: 30px;
			padding-bottom: 10px;
			@include font-size(24);
		}
		@include mq(xs) {
			@include font-size(22);
		}
		@include p-after(200px, 8px){
			background: #3979F6;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			@include mq{
				height: 4px;
			}
		}
	}
	&__05 {
		font-weight: bold;
		> * {
			display: block;
		}
		.en {
			@include font-size(13);
			color: #0079FF;
			margin-bottom: 6px;
			@include mq {
				@include font-size(12);
			}
		}
		.jp {
			@include font-size(24);
			@include mq {
				@include font-size(18);
			}
			@include mq(xs) {
				@include font-size(15);
			}
		}
	}
}

//btn
.c-btn {
	&__01 {
		position: relative;
		@include flex(center, center);
		text-align: center;
		width: 100%;
		max-width: 365px;
		height: 83px;
		background: white;
		color: black;
		border-radius: 50px;
		font-weight: bold;
		@include font-size(24);
		padding: 5px 70px;
		margin: 0 auto;
		cursor: pointer;
		@include mq {
			max-width: 212px;
			height: 48px;
			@include font-size(13);
			padding: 5px 40px;
		}
		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-top: 9px solid transparent;
			border-left: 16px solid black;
			border-bottom: 9px solid transparent;
			top: 33px;
			right: 47px;
			transition: 0.3s ease;
			@include mq {
				border-top: 6px solid transparent;
				border-left: 8px solid #000;
				border-bottom: 6px solid transparent;
				top: 19px;
				right: 28px;
			}
		}
		&:hover {
			opacity: 1;
			background: black;
			color: white;
			&:after {
				border-left-color: white;
				right: 42px;
				transition: 0.3s ease;
				@include mq {
					right: 23px;
				}
			}
		}
		&--txt{
			@include fts(20, 36);
			padding: 0 35px 0 0;
			width: auto;
			height: auto;
			border-radius: 0;
			border: 0;
   	 		float: right;
			@include mq{
				@include font-size(15);
			}
			&::after{
				right: 11px;
				top: 13px;
				border-width: 6px 0 6px 13px;
				@include mq{
					top: 8px;
				}
			}
			&:hover{
				background: transparent;
				color: #000;
				&::after{
					right: 5px;
					border-left-color: #000;
				}
			
			}
		}
		&--bgwhite{
			border: 2px solid currentColor;
		}
		&--black{
			color: white;
			background: black;
			border: 2px solid black;
			&::after{
				border-left-color: currentColor;
			}
			&:hover{
				color: black;
				border-color: currentColor;
				background: white;
				&:after {
					border-left-color: currentColor;
				}
			}
		}
		&--gradient{
			background: linear-gradient(90deg, #DBAAB6, #3979F6);
			border: 2px solid;
   	 		color: white;
			&::after{
				border-left-color: currentColor;
			}
			&:hover{
				color: #3979F6;
				border-color: currentColor;
				background: white;
				&:after {
					border-left-color: currentColor;
				}
			}
		}
	}
	&__scroll {
		position: absolute;
		color: black;
		font-weight: 500;
		@include font-size(20);
		padding-bottom: 30px;
		bottom: 42px;
		@include h-align;
		-webkit-animation: arrowmove 1s ease-in-out infinite;
		animation: arrowmove 1s ease-in-out infinite;
		@include mq {
			display: none;
		}
		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-left: 13px solid transparent;
			border-right: 13px solid transparent;
			border-top: 24px solid black;
			bottom: 0;
			@include h-align;
		}
	}
	&__02{
		max-width: 209px;
		width: 100%;
		height: 48px;
		border: 1px solid #000;
		@include flex(center,center);
		background: #000;
		border-radius: 42px;
		color: #fff;
		font-weight: bold;
		position: relative;
		margin: 0 auto;
		@include font-size(13);
		@include mq(){
			@include font-size(12);
		}
		&:before {
			content: "";
			position: absolute;
			background: url("/img/index/icon-link-white.svg") center no-repeat;
			background-size: contain;
			width: 14px;
			height: 14px;
			top: 50%;
			transform: translateY(-50%);
			right: 20px;
			transition: 0.3s ease;
		}

		&:after {
			content: "";
			position: absolute;
			background: url("/img/index/arrow-white.svg") center no-repeat;
			background-size: contain;
			width: 10px;
			height: 10px;
			top: 13px;
			right: 17px;
			transition: 0.3s ease;
		}
		
		&:hover {
			opacity: 1;
			@include mq(md-min){
				background: white;
				color: black;
				&:before {
					background: url("/img/index/icon-link-black.svg") center no-repeat;
					background-size: contain;
					transition: 0.3s ease;
				}
				&:after {
					background: url("/img/index/arrow-black.svg") center no-repeat;
					background-size: contain;
					transition: 0.3s ease;
					top: 11px;
					right: 15px;
				}
			}
		}
	}
}


//fade up
.js-scrollin {
	opacity: 0;
	transform: translate(0, 70px);

	&.active {
		opacity: 1;
		transform: translate(0, 0);
		transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}
}

@-webkit-keyframes arrowmove {
	0% {
		bottom: 42px;
	}
	50% {
		bottom: 52px;
	}
	100% {
		bottom: 42px;
	}
}
@keyframes arrowmove {
	0% {
		bottom: 42px;
	}
	50% {
		bottom: 52px;
	}
	100% {
		bottom: 42px;
	}
}
