.page-consultation{
	.page-mv {
		padding: 188px 0 42px;
		@include mq(){
			padding: 60px 0;
		}
	}
}
.page-mv{
	&__ttl{
		color: #fff;
		text-align: center;
		font-weight: bold;
		margin-bottom: 38px;
		@include font-size(36);
		@include mq(xl){
			@include font-size(30);
		}
		@include mq(){
			margin-bottom: 20px;
			@include font-size(20);
		}
		@include mq(xs){
			@include font-size(18);
		}
		&--lg {
			@include font-size(52);
			margin-bottom: 18px;
			@include mq(xl) {
				@include font-size(40);
			}
			@include mq {
				@include font-size(30);
				margin-bottom: 15px;
			}
			@include mq(xs) {
				@include font-size(20);
			}
		}
	}
	&__list{
		width: 100%;
		max-width: 805px;
		margin: 0 auto;
		@include flex(0,center);
		@include mq(sm){
			margin: 0 -15px;
			width: calc(100% + 30px);
			@include flex(center,0,wrap);
		}
	}
	&__item{
		width: 33.33%;
		padding: 0 17px;
		@include mq(sm){
			width: 50%;
			padding: 0 4px;
		}
		span{
			padding: 30px 10px;
			color: #fff;
			@include flex(center,center);
			height: 175px;
			text-align: center;
			font-weight: bold;
			line-height: 1.5;
			@include font-size(16);
			background: url('../img/consultation/bg-01.png') center no-repeat;
			background-size: 100% 100%;
			@include mq(){
				@include font-size(14);
			}
			@include mq(sm){
				max-width: 300px;
				width: 100%;
				margin: 0 auto;
				height: 150px !important;
			}
			@include mq(xs){
				@include font-size(12);
			}
		}
		&:nth-child(1){
			span{
				max-width: 214px;
			}
		}
		&:nth-child(2){
			span{
				background: url('../img/consultation/bg-02.png') center no-repeat;
				height: 190px;
				background-size: 100% 100%;
			}
		}
		&:nth-child(3){
			span{
				background: url('../img/consultation/bg-03.png') center no-repeat;
				height: 204px;
				background-size: 100% 100%;
			}
		}
	}
}

.sec-works{
    padding: 80px 0 14px;
	@include mq(){
		padding: 50px 0 0;
	}
}

.works-flex{
	@include flex();
	@include mq(){
		@include flex(0,0,wrap);
	}
	&__img{
		width: 53.35%;
		padding: 0 41px 0 125px;
		@include mq(xl){
			padding: 0 30px 0 0;
		}
		@include mq(){
			padding: 0;
			width: 100%;
			margin-bottom: 15px;
		}
		img{
			width: 100%;
		}
	}
	&__ct{
		width: 46.65%;
		font-weight: 500;
		padding-top: 54px;
		@include mq(){
			padding-top: 0;
			width: 100%;
		}
	}
}

.sec-point{
	padding: 50px 0 97px;
	background: url('../img/consultation/bg.png') top 0 left 51% no-repeat;
    background-size: 115%;
	@include mq(){
		padding: 50px 0;
		background: none;
	}
	.c-ttl__04{
		margin-bottom: 91px;
		@include mq(){
			margin-bottom: 25px;
		}
	}
}

.point-list{
	&__item{
	    margin-bottom: 49px;
		@include mq(xl){
			margin-bottom: 80px;
		}
		@include mq(){
			margin-bottom: 35px;
			&:nth-child(1){
				margin-bottom: 75px;
			}
			&:nth-child(4){
				margin-bottom: 95px;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
		&:nth-child(2n){
			text-align: right;
		}
		@include mq(md-min){
			&:nth-child(2){
				.point-list__ct{
					padding: 35px 74px 52px;
				}
			}
		}
	}
	&__ct{
		max-width: 747px;
		width: 100%;
		text-align: left;
		border-radius: 20px;
		border: 2px solid #000;
		padding: 48px 60px 57px;
		background: #fff;
		display: inline-block;
		position: relative;
		@include mq(){
			max-width: 100%;
			padding: 30px 15px;
			border-width: 1px;
			border-radius: 10px;
		}
	}
	&__number{
		color: #0079FF;
		font-weight: bold;
		margin-bottom: 13px;
		line-height: 1.2;
		@include font-size(13);
		@include mq(){
			margin-bottom: 10px;
			@include font-size(12);
		}
	}
	&__ttl{
		color: #000;
		line-height: 1.3;
		font-weight: bold;
	    margin-bottom: 32px;
		@include font-size(24);
		@include mq(){
			margin-bottom: 15px;
			@include font-size(18);
		}
	}
	&__img{
		max-width: 203px;
		width: 100%;
		position: absolute;
		top: -90px;
		right: 41px;
		@include mq(xl){
			max-width: 150px;
			top: -70px;
			right: 30px;
		}
		@include mq(){
			max-width: 100px;
			right: 10px;
			top: -50px;
		}
		img{
			width: 100%;
		}
		&--02{
			max-width: 198px;
			top: -179px;
			right: auto;
			left: 33px;
			@include mq(xl){
				max-width: 150px;
				left: 30px;
				top: -120px
			}
			@include mq(){
				max-width: 100px;
				left: 10px;
				top: -85px;
			}
		}
	}
	&__flex{
		@include flex(center,center);
		@include mq(){
			@include flex(0,0,wrap);
		}
		img{
			width: 108px;
			@include mq(){
				width: 10%;
				width: 110px;
				margin: 0 auto 15px;
			}
		}
		p{
			padding-left: 40px;
			@include mq(){
				padding-left: 0;
			}
		}
	}
}

.sec-consultation{
	background: #EDF3F5;
	padding: 70px 0 160px;
	@include mq(){
	    padding: 50px 0 80px;
	}
	.c-txt__01{
		text-align: center;
		font-weight: bold;
		margin-bottom: 24px;
	}
	.c-ttl__04{
		margin-bottom: 40px;
		@include mq(){
			margin-bottom: 25px;
		}
	}
}

.consultation-list{
	&__item{
		padding: 70px 15px 0;
		@include mq(sm){
			margin: 0 40px;
			padding: 70px 0 0;
		}
	}
	&__ct{
		background: #fff;
		border-radius: 20px;
		padding: 36px;
		display: block;
		@include mq(xl){
			padding: 30px 20px;
		}
		p{
			color: #000;
		    margin-bottom: 30px;
			@include mq(){
				margin-bottom: 20px;
			}
		}
		position: relative;
		z-index: 3;
		&:before {
			content: "";
			position: absolute;
			background: white;
			width: calc(100% - 4px);
			height: calc(100% - 16px);
			border: 2px solid black;
			border-radius: 20px;
			top: 0;
			left: 0;
			opacity: 0;
			transition: 0.3s ease;
			z-index: -1;
		}

		&:hover {
			opacity: 1;
			background: black;
			transition: 0.3s ease;
			&:before {
				opacity: 1;
				transition: 0.3s ease;
			}
			// .c-btn__02{
			// 	opacity: 1;
			// 	@include mq(md-min){
			// 		background: white;
			// 		color: black;
			// 		&:before {
			// 			background: url("/img/index/icon-link-black.svg") center no-repeat;
			// 			background-size: contain;
			// 			transition: 0.3s ease;
			// 		}
			// 		&:after {
			// 			background: url("/img/index/arrow-black.svg") center no-repeat;
			// 			background-size: contain;
			// 			transition: 0.3s ease;
			// 			top: 11px;
			// 			right: 15px;
			// 		}
			// 	}
			// }
		}
	}
	&__img{
		margin-top: -100px;
		text-align: center;
		margin-bottom: 26px;
		img{
			width: 130px;
			height: 130px;
			border-radius: 50%;
			object-fit: cover;
			margin: 0 auto;
		}
	}
	&__ttl{
		color: #000;
		margin-bottom: 35px;
		font-weight: bold;
		line-height: 1.5;
		padding: 0 10px;
		@include font-size(24);
		@include mq(xl){
			padding: 0;
			@include font-size(20);
		}
		@include mq(){
			margin-bottom: 15px;
			padding: 0;
			@include font-size(18);
		}
	}
	.slick-prev,.slick-next{
		@include mq(sm){
			top: 57%;
		}
	}
}