.sec-contact {
	background: linear-gradient(90deg, #3979F6, #DBAAB6);
	color: white;
	padding: 77px 0 59px;
	@include mq {
		background: linear-gradient(45deg, #3979F6, #DBAAB6);
		padding: 73px 0 76px;
	}
	.c-txt__01{
		margin-bottom: 52px;
		@include mq(){
			margin-bottom: 25px;
		}
	}
}

.contact-ttl {
	text-align: center;
	font-weight: bold;
	@include font-size(36);
    margin-bottom: 31px;
	@include mq(xl) {
		@include font-size(28);
	}
	@include mq {
		@include fts(20, 36);
		margin-bottom: 40px;
	}
	@include mq(xs) {
		@include font-size(18);
	}
}

.contact-txt {
	max-width: 526px;
	@include flex(between);
	font-weight: 500;
	@include font-size(24);
	margin: 0 auto 56px;
	@include mq {
		max-width: 307px;
		margin-bottom: 25px;
		@include font-size(14);
	}
	@include mq(xs) {
		@include font-size(13);
	}
	span {
		border-bottom: 1px solid white;
		padding-bottom: 4px;
		@include mq {
			padding-bottom: 2px;
		}
		a {
			color: white;
			margin-left: 4px;
			@include mq {
				margin-left: 3px;
			}
		}
	}
}

.js-backtop {
	position: absolute;
	width: 51px;
	height: 51px;
	border-radius: 50%;
	border: 1px solid black;
	top: -6px;
	right: -2px;
	@include mq(lp) {
		top: 5px;
		right: 20px;
	}
	@include mq {
		width: 29px;
		height: 29px;
	}
	&:after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-bottom: 19px solid black;
		top: 14px;
		@include h-align;
		transition: 0.3s ease;
		@include mq {
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-bottom: 12px solid #000;
			top: 6px;
		}
	}
	&:hover {
		opacity: 1;
		&:after {
			top: 7px;
			transition: 0.3s ease;
			@include mq {
				top: 3px;
			}
		}
	}
}

#footer{
	padding: 123px 0 100px;
	@include mq {
		padding: 50px 0;
	}
	.inner {
		position: relative;
		max-width: 1407px;
		@include mq(sm) {
			padding: 0 40px;
		}
	}
	.footer-wrap {
		@include flex(between, start, wrap);
		margin-bottom: 71px;
		@include mq(sm) {
			max-width: 295px;
			margin: 0 auto 46px;
		}
		&__logo {
			width: 294px;
			@include mq(ipd) {
				width: 155px;
			}
			img {
				width: 100%;
			}
		}
		&__list {
			width: calc(100% - 397px);
			@include flex();
			padding-top: 10px;
			@include mq(ipd) {
				width: calc(100% - 200px);
				padding-top: 0;
			}
			@include mq(sm) {
				width: 100%;
				margin-top: 17px;
			}
			&-item {
				margin-right: 127px;
				@include mq(ipd) {
					margin-right: 80px;
				}
				@include mq(sm) {
					margin-right: 35px;
				}
				a {
					display: block;
					color: black;
					@include font-size(16);
					margin-bottom: 6px;
					@include mq {
						font-weight: 500;
						@include font-size(13);
						margin-bottom: 4px;
					}
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.footer-copy {
		@include font-size(16);
		@include mq {
			font-weight: 300;
			@include font-size(13);
		}
	}
}